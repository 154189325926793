import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { FileActions, JoinerActions } from "../state";
import { Joiner as View } from "./Joiner";

export const Joiner = connect(
  function mapState(state) {
    const {
      files,
      joiner: {
        allowFileIds,
        error,
        filesToJoin,
        joiningToFileName,
        loading,
        sortedFileIds,
      },
    } = state;
    return {
      allowFileIds,
      error,
      files,
      filesToJoin,
      joiningToFileName,
      loading,
      sortedFileIds,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: {
        ...bindActionCreators(FileActions, dispatch),
        ...bindActionCreators(JoinerActions, dispatch),
      },
    };
  },
)(View);
