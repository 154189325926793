import axios from "axios";
import { getQueryVariable } from "../lib";

const { NODE_ENV, REACT_APP_API_URL } = process.env;

const __DEV__ = NODE_ENV === "development";

const { localStorage } = window;

const authToken =
  // get Token from url if passed in as querystring ?token=...
  getQueryVariable("token") ||
  // Token from legacy www client:
  localStorage.getItem("ta3_token") ||
  // Token from react-admin:
  localStorage.getItem("token");

/** @param {AxiosRequestConfig} config */
function applyAuthHeaders(config) {
  config.headers.Authorization = `Bearer ${authToken}`;
  return config;
}

/** Axios instance to use for API requests. */
export const APIClient = axios.create({
  baseURL: REACT_APP_API_URL,
});

if (authToken) {
  APIClient.interceptors.request.use(
    applyAuthHeaders,
    // CONSIDER: An error handler can be passed. (Useful for refresh token
    // logic, to retry requests after refreshing the access token.)
    // (err) => Promise.reject(err),
  );
}

export const authTokenMissingMessage =
  "DEVELOPERS: " +
  "Copy 'token' into uploader localStorage from react-admin-frontend " +
  "localStorage if loading the uploader iframe from a different " +
  "domain or port.";

if (__DEV__) {
  // Expose APIClient to developer...
  window.APIClient = APIClient;
  if (!authToken) {
    console.error("ERROR: 'ta3_token' or 'token' not found in localStorage.");
    console.error(authTokenMissingMessage);
  }
}

export default APIClient;

// #region Typedefs

/** @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig */

// #endregion
