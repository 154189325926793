import { FileActions } from "../files/actions";
import { TrimmerActions } from "./actions";
import { UIActions } from "../ui/actions";
import { PublishingActions } from "../publishing/actions";

export const TrimmerState = {
  name: "trimmer",
  persist: false,
  defaults: {
    /*
    fileToTrim: '',
    loading: false,
    markersInvalid: false,
    preview_begin: false,
    preview_begin_error: '',
    preview_end: false,
    preview_end_error: '',
  */
  },
  handlers: {
    [FileActions.FILE_DELETE]: (state, action) => {
      if (state.fileToTrim !== action.payload) {
        return state;
      }
      return {
        ...state,
        fileToTrim: "",
        markersInvalid: false,
        preview_begin: false,
        preview_end: false,
      };
    },
    [FileActions.FILES_LOAD_ALL]: (state, action) => {
      return {
        ...state,
        loading: action.payload,
        markersInvalid: false,
        preview_begin: false,
        preview_end: false,
      };
    },
    [PublishingActions.FILE_SUBMIT]: (state, action) => {
      if (state.fileToTrim !== action.payload.fileId) {
        return state;
      }
      // The selected file is being submitted. Unselect it now.
      return {
        ...state,
        fileToTrim: "",
        markersInvalid: false,
        preview_begin: false,
        preview_end: false,
      };
    },
    [TrimmerActions.TRIM_FILE_MARK]: (state, action) => {
      return {
        ...state,
        markersInvalid: false,
      };
    },
    [TrimmerActions.TRIM_FILE_MARK_INVALID]: (state, action) => {
      return {
        ...state,
        markersInvalid: true,
      };
    },
    [TrimmerActions.TRIM_FILE_PREVIEW]: (state, action) => {
      const { error, part } = action.payload;
      return {
        ...state,
        [`preview_${part}_error`]: error,
      };
    },
    [TrimmerActions.TRIM_FILE_PREVIEW_CHANGE]: (state, action) => {
      const { part } = action.payload;
      return {
        ...state,
        [`preview_${part}`]: false,
        [`preview_${part}_error`]: undefined,
      };
    },
    [TrimmerActions.TRIM_FILE_PREVIEW_LOAD]: (state, action) => {
      const { part } = action.payload;
      return {
        ...state,
        [`preview_${part}`]: true,
        [`preview_${part}_error`]: undefined,
      };
    },
    [TrimmerActions.TRIM_FILE_SELECT]: (state, action) => {
      const fileToTrim =
        state.fileToTrim === action.payload ? "" : action.payload;
      return {
        ...state,
        fileToTrim,
        markersInvalid: false,
        preview_begin: false,
        preview_end: false,
      };
    },
    [UIActions.TAB_SELECT]: (state, action) => {
      if (action.payload === 1) {
        return state;
      }
      return {
        ...state,
        markersInvalid: false,
        preview_begin: false,
        preview_end: false,
      };
    },
  },
};
