import React from "react";
import classNames from "classnames";
import ReactModal from "react-modal";
import { closestAttribVal } from "../lib";

export class TransferToUserModal extends React.Component {
  // #region Private vars, state
  //
  /** Non-state variable. DO NOT use this variable for rendering. */
  requestedUsers = false;
  /** Non-state variable. DO NOT use this variable for rendering. */
  transferring = false;
  state = {
    searchTerm: "",
    searchTermPartsLower: undefined,
    selectedUserId: undefined,
  };
  // #endregion
  // #region Event Handlers
  //
  /** @param {React.ChangeEvent<HTMLInputElement>} e */
  onChangeSearchTerm = e => {
    const searchTerm = e.target.value;
    this.setState({
      searchTerm,
      searchTermLower: searchTerm.toLowerCase(),
    });
  };
  onClickOK = () => {
    if (this.validateOK()) {
      this.confirmAndTransferFile();
    }
  };
  /** @param {React.MouseEvent<HTMLLIElement>} e */
  onClickUser = e => {
    const id = closestAttribVal(e.currentTarget, "data-id");
    this.setState({
      selectedUserId: id,
    });
  };
  /** @param {React.MouseEvent<HTMLLIElement>} e */
  onDoubleClickUser = e => {
    const id = closestAttribVal(e.currentTarget, "data-id");
    this.setState({
      selectedUserId: id,
    });
    this.confirmAndTransferFile();
  };
  onRequestClose = () => {
    if (this.transferring) {
      return;
    }
    this.setState({
      searchTerm: "",
      searchTermPartsLower: undefined,
      selectedUserId: undefined,
    });
    this.props.onRequestClose();
  };
  // #endregion
  // #region Component methods
  //
  componentDidUpdate(prevProps /* , prevState */) {
    if (prevProps !== this.props) {
      this.maybeGetUsers();
    }
  }
  async confirmAndTransferFile() {
    if (this.transferring || !window.confirm("Are you sure?")) {
      return;
    }
    const {
      props: {
        actions: { transferToUser },
        file,
      },
      state: { selectedUserId },
    } = this;
    this.transferring = true;
    await transferToUser(file.id, selectedUserId).catch(err => {
      this.transferring = false;
      window.alert("There was an error. Support has been notified.");
    });
    this.transferring = false;
    // TODO: Call the transfer action and wait here for it to succeed.
    this.onRequestClose();
  }
  getUsers() {
    const {
      actions: { getUsers },
    } = this.props;
    getUsers();
  }
  maybeGetUsers() {
    const { isOpen, users } = this.props;
    if (isOpen && !users && !this.requestedUsers) {
      this.requestedUsers = true;
      this.getUsers();
    }
  }
  validateOK() {
    const {
      state: { selectedUserId },
    } = this;
    if (!selectedUserId) {
      window.alert("Please select a user.");
      return false;
    }
    return true;
  }
  render() {
    const {
      props: { file, isOpen, loadingUsers, users },
      state: { searchTerm, searchTermLower, selectedUserId },
    } = this;
    if (!isOpen || !file) {
      return null;
    }
    return (
      <ReactModal
        ariaHideApp={false}
        contentLabel="Transfer File"
        isOpen={isOpen}
        onRequestClose={this.onRequestClose}
        shouldReturnFocusAfterClose={false}
        style={{
          content: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 50,
            padding: 0,
            paddingBottom: 50,
          },
        }}
      >
        <div className="modal_header">
          <h3>Transfer File</h3>
          <div className="modal_header_buttons">
            <button onClick={this.onClickOK}>OK</button>{" "}
            <button onClick={this.onRequestClose}>Cancel</button>
          </div>
          <div className="modal_header_body">
            <center>
              <strong>
                <em>{file.name}</em>
              </strong>
            </center>
            <input
              type="text"
              value={searchTerm}
              onChange={this.onChangeSearchTerm}
              autoFocus={true}
              placeholder="Search: Name | Email"
              style={{ marginTop: 10, width: "100%" }}
            />
          </div>
        </div>
        <div className="modal_body">
          {loadingUsers && (
            <center>
              <strong>
                <em>Loading...</em>
              </strong>
            </center>
          )}
          <ul className="user_list">
            {users &&
              Object.keys(users).map(userId => {
                const user = users[userId];
                if (
                  searchTerm &&
                  !(
                    user.name.toLowerCase().indexOf(searchTermLower) > -1 ||
                    user.email.toLowerCase().indexOf(searchTermLower) > -1
                  )
                ) {
                  return null;
                }
                return (
                  <li
                    key={userId}
                    className={classNames({
                      active: userId === selectedUserId,
                    })}
                    data-id={userId}
                    onClick={this.onClickUser}
                    onDoubleClick={this.onDoubleClickUser}
                  >
                    {user.name}
                    <span style={{ float: "right" }}>{user.email}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      </ReactModal>
    );
  }
  // #endregion
}
