import axios from "axios";
import { captureException, withScope } from "@sentry/browser";
import { PQueue } from "../../lib";
import {
  getContentHash,
  handleFileProgress,
  replaceHostWithFallbackURL,
  shouldTestUploadError,
} from "./shared";

/** Default upload part size in bytes. (10mb) */
const DEFAULT_PARTSIZE = 10000000;
/** Default number of parts to upload at the same time. */
const DEFAULT_PARTS_CONCURRENT = 2;

export class MultipartFileUploader {
  /** Creates a new `MultipartFileUploader`.
   * @param {MultipartFileUploaderParams} params
   */
  constructor(params) {
    /** The file being uploaded. */
    this.file = params.file;
    /** Gets the signed URL to upload a single part of the file. */
    this.onGetUploadPartURL = params.onGetUploadPartURL;
    const {
      fallbackAfter,
      fallbackURL,
      onUploadError,
      onUploadProgress,
      partsConcurrent = DEFAULT_PARTS_CONCURRENT,
      retryWait,
      timeout = 0,
      timeStarted,
    } = params;
    /** @type {number} */
    this.fallbackAfter = fallbackAfter;
    /** @type {string} */
    this.fallbackURL = fallbackURL;
    if (typeof onUploadError === "function") {
      this.onUploadError = onUploadError;
    }
    if (typeof onUploadProgress === "function") {
      this.onUploadProgress = onUploadProgress;
    }
    /** @type {number} */
    this.partsConcurrent = partsConcurrent;
    /** Upload part size in bytes. */
    this.partSize = params.partSize || DEFAULT_PARTSIZE;
    /** Part upload queue. */
    this.queue = new PQueue({
      concurrency: partsConcurrent,
    });
    this.retryWaitCount = 0;
    /** @type {number} */
    this.retryWaitInit = retryWait.init;
    /** @type {number} */
    this.retryWaitFactor = retryWait.factor;
    /** @type {number} */
    this.retryWaitMax = retryWait.max;
    /** Milliseconds timeout for Axios.
     * @type {number} */
    this.timeout = timeout;
    /** @type {Date} */
    this.timeStarted = timeStarted;
    /** Headers to attach during upload. */
    this.upload_headers = params.upload_headers;
    /** Id of the multpart upload. */
    this.multipart_id = params.multipart_id;
    /** Missing parts of the failed upload, for resuming. */
    this.multipart_missing = params.multipart_missing;
    /** Last completed part of the failed upload, for resuming. */
    this.multipart_last = params.multipart_last;
    /** True if resuming from failed upload. */
    this.resuming = params.resuming;
    /** True to disable MD5 hashing. */
    this.verifyContentHashDisabled = params.verifyContentHashDisabled;

    if (this.resuming) {
      this.partSize = params.multipart_sizes;
    }
  }
  /** Cancels the current upload. */
  cancel = message => {
    const { uploadingParts } = this;
    this.cancelled = true;
    if (this.retryWaitTimer) {
      clearTimeout(this.retryWaitTimer);
      if (this.retryWaitStop) {
        this.retryWaitStop();
      }
    }
    Object.keys(uploadingParts).forEach(partNumKey => {
      try {
        const { cancel } = uploadingParts[partNumKey];
        if (cancel) {
          cancel(message);
        }
      } catch (err) {
        console.error(err);
      }
    });
    this.queue.clear();
  };

  onRetryWait = () => {
    if (this.retryWaitPromise) {
      // Concurrent parts should join the same wait...
      return this.retryWaitPromise;
    }
    this.retryWaitCount += 1;
    if (this.retryWaitCount > 1) {
      this.retryWaitTimeout = Math.min(
        this.retryWaitTimeout * this.retryWaitFactor,
        this.retryWaitMax,
      );
    } else {
      this.retryWaitTimeout = this.retryWaitInit;
    }
    console.log(`Waiting ${this.retryWaitTimeout}...`);
    this.startRetryCountdown(this.retryWaitTimeout);
    this.retryWaitPromise = new Promise(resolve => {
      this.retryWaitStop = () => {
        console.log("Done waiting...");
        delete this.retryWaitStop;
        delete this.retryWaitTimer;
        delete this.retryWaitPromise;
        resolve();
      };
      this.retryWaitTimer = setTimeout(
        this.retryWaitStop,
        this.retryWaitTimeout,
      );
    });
    return this.retryWaitPromise;
  };

  retryCountdown = () => {
    const remaining = this.retryWaitRemaining / 1000;
    this.onUploadProgress(undefined, `Retrying in ${remaining}...`);
    this.retryWaitRemaining = this.retryWaitRemaining - 1000;
    if (this.retryWaitRemaining > 0) {
      setTimeout(this.retryCountdown, 1000);
    }
  };

  startRetryCountdown = remaining => {
    if (
      !this.retryWaitRemaining ||
      this.retryWaitRemaining <= 0 ||
      remaining < this.retryWaitCount
    ) {
      this.retryWaitRemaining = remaining;
      this.retryCountdown();
    }
  };
  /**
   * @param {ProgressEvent & {partNum:number,progress:number}} e Native
   * `ProgressEvent` with extra props.
   * @param {number|string} progress Calculated progress percentage
   * (`0.00 - 100.00`) or a progress message.
   */
  onPartUploadProgress = e => {
    const { onUploadProgress, totalUploadParts, uploadingParts } = this;
    if (!uploadingParts[e.partNum]) {
      return;
    }
    uploadingParts[e.partNum].progress = e.progress;
    const sumProgress = Object.keys(uploadingParts).reduce((sum, key) => {
      const { progress } = uploadingParts[key];
      return sum + progress;
    }, 0.0);
    const totalProgress =
      Math.round((sumProgress / totalUploadParts) * 100) / 100;
    e.progress = totalProgress;
    onUploadProgress(e, `${totalProgress}%`);
  };
  /** Default error handler.
   * @param {Error & {level:string, type:string}} error
   */
  onUploadError = function(error) {
    console.error(error);
  };
  /** Upload progress event handler.
   * @param {ProgressEvent} e Native `ProgressEvent`
   * @param {number} progress Calculated progress percentage. (`0.00 - 100.00`)
   */
  onUploadProgress = function(e, progress) {};
  /** Starts the upload.
   * @returns {Promise<boolean>} True if successful. */
  start = async () => {
    let {
      file,
      multipart_last = 0,
      multipart_missing = [],
      partSize,
      resuming,
    } = this;
    const fileSize = file.size;
    const numParts = Math.ceil(fileSize / partSize) + 1;
    this.totalUploadParts = numParts;
    const addPart = i => {
      const start = (i - 1) * partSize;
      const end = i * partSize;
      this.queue.add(this.uploadPart(i, start, end, numParts));
    };
    if (resuming) {
      console.log("Resuming upload...");
      multipart_missing.forEach(addPart);
      for (let i = multipart_last + 1; i < numParts; i++) {
        addPart(i);
      }
    } else {
      for (let i = 1; i < numParts; i++) {
        addPart(i);
      }
    }
    await this.queue.onIdle();
    this.uploadingParts = {};
    return !this.cancelled && !this.failed;
  };

  /** Total number of parts to upload. */
  totalUploadParts = 0;
  /** Map of parts being uploaded, by part number.
   * @type {{[partNum:string]: UploadingPart}} */
  uploadingParts = {};

  /** Returns a queueable function to upload the given part. */
  uploadPart(partNum, start, end, numParts) {
    return async () => {
      if (this.cancelled) {
        return;
      }
      const {
        fallbackAfter,
        fallbackURL,
        file,
        onGetUploadPartURL,
        multipart_id,
        onPartUploadProgress,
        timeout,
        verifyContentHashDisabled,
      } = this;
      const blob =
        partNum < numParts ? file.slice(start, end) : file.slice(start);
      const contentMD5 = verifyContentHashDisabled
        ? ""
        : await getContentHash(blob);
      let url = await onGetUploadPartURL(multipart_id, partNum, contentMD5);
      if (url === false) {
        window.alert("You are no longer authorized for uploads.");
        this.cancel("Unauthorized");
        return;
      }
      if (this.usingFallback) {
        url = replaceHostWithFallbackURL(url, fallbackURL);
      }
      /** @type {UploadingPart} */
      const uploadingPart = {
        partNum,
        progress: 0,
      };
      this.uploadingParts[partNum] = uploadingPart;
      const uploadConfig = {
        cancelToken: new axios.CancelToken(cancel => {
          uploadingPart.cancel = cancel;
        }),
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: handleFileProgress((e, progress) => {
          e.partNum = partNum;
          onPartUploadProgress(e, progress);
        }),
        timeout,
      };
      if (!verifyContentHashDisabled) {
        uploadConfig.headers["Content-MD5"] = shouldTestUploadError
          ? "INCORRECT-MD5"
          : contentMD5;
      }
      /** Only use `this.useFallback` to initialize! Disable for error test. */
      let usingFallback = shouldTestUploadError || this.usingFallback;
      /** @param {import('axios').AxiosError} err */
      const onError = err => {
        if (axios.isCancel(err)) {
          this.cancelled = true;
          console.log(`Cancelled upload of "${file.name}"`);
          return undefined;
        }
        console.warn(
          `${err.message} - { code: ${err.code}, ` +
            `response: ${!!err.response}, ` +
            `status: ${err.response ? err.response.status : ""} }`,
        );
        withScope(scope => {
          scope.setTag("axerr-code", err.code);
          scope.setTag("axerr-response", !!err.response);
          scope.setTag(
            "axerr-response-status",
            err.response ? err.response.status : "",
          );
          scope.setTag("axerr-status", err.status);
          captureException(err);
        });
        // Detect network error and retry with fallbackURL
        // - Based on our logging, err.response is missing when there is a true
        // network error.
        // - https://github.com/axios/axios/issues/383#issuecomment-316501886
        if (!usingFallback && Date.now() - this.timeStarted >= fallbackAfter) {
          // We don't need this warning because we're just going to keep trying...
          // if (!this.warned) {
          //   this.warned = true;
          //   this.onUploadError({ level: 'warning', type: 'fallback' });
          // }
          console.log("Using fallback url...");
          usingFallback = true; // Set for this part.
          this.usingFallback = true; // Set for future parts.
          const tryURL = replaceHostWithFallbackURL(url, fallbackURL);
          delete uploadingPart.cancel;
          return axios.put(tryURL, file, uploadConfig).catch(onError);
        }
        if (this.cancelled) {
          return;
        }
        // Retry continually:
        return this.onRetryWait().then(() => {
          if (this.cancelled) {
            return;
          }
          delete uploadingPart.cancel;
          return axios.put(url, blob, uploadConfig).catch(onError);
        });
        // To stop trying and fail:
        // delete uploadingPart.cancel;
        // if (!this.failed) {
        //   this.failed = true;
        //   this.onUploadError(err);
        //   this.queue.clear();
        // }
      };
      await axios.put(url, blob, uploadConfig).catch(onError);
      delete uploadingPart.cancel;
      uploadingPart.progress = 100.0;
    };
  }
  /** True if the fallback URL should be used immediately for the next part. */
  usingFallback = false;
}
export default MultipartFileUploader;

// #region Typedefs
/** @typedef {object} MultipartFileUploaderParams
 * @property {File} file
 * @property {string} multipart_id
 * @property {object} upload_headers
 * @property {number} [partSize] Size of each upload part in bytes. (**10mb**)
 * @property {(multipart_id:string,part:number)=> Promise<string>}
 * onGetUploadPartURL
 * @property {(e:ProgressEvent,progress:number)=>void} [onUploadProgress] Upload
 * progress event handler.
 * @property {boolean} resuming
 * @property {number} [multipart_last]
 * @property {number[]} [multipart_missing]
 * @property {number} [multipart_sizes] Upload part size in bytes from when the
 * failed upload started.
 */
/** @typedef {object} UploadingPart
 * @property {number} partNum
 * @property {(message:string)=>void} [cancel]
 * @property {number} progress
 */
// #endregion
