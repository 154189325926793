import React from "react";
import classNames from "classnames";
import { sortObjectKeysByNameAndId } from "../lib";
import { FileList, PreviewPlayer } from "../components";

const DEFAULT_PREVIEW_SECONDS = 10;

export class Trimmer extends React.PureComponent {
  onMarkTime = e => {
    const {
      actions: { markTrimFile },
      fileToTrim,
    } = this.props;
    markTrimFile(fileToTrim, e.part, e.markedSeconds);
  };

  /** @param {React.MouseEvent<HTMLButtonElement>} e */
  onPreview = e => {
    const part = e.target.attributes["data-part"].value;
    const {
      actions: { previewTrimFile },
      fileToTrim,
      files: {
        [fileToTrim]: {
          ["preview_" + part + "_seconds"]: seconds = DEFAULT_PREVIEW_SECONDS,
        },
      },
    } = this.props;
    previewTrimFile(fileToTrim, part, seconds);
  };

  onPreviewChangeSeconds = e => {
    const part = e.target.attributes["data-part"].value;
    const seconds = parseInt(e.target.value, 10);
    const {
      actions: { changeTrimFilePreview },
      fileToTrim,
    } = this.props;
    changeTrimFilePreview(fileToTrim, part, seconds);
  };

  onResetTrim = e => {
    const {
      actions: { resetTrimFile },
      fileToTrim,
    } = this.props;
    resetTrimFile(fileToTrim);
  };

  render() {
    const {
      actions: { loadAllFiles, selectTrimFile },
      fileToTrim,
      files,
      files: { [fileToTrim]: selectedFile },
    } = this.props;
    const fileIds = sortObjectKeysByNameAndId(
      files,
      Object.keys(files).filter(
        id => files[id].upload_completed && !files[id].is_corrupt,
      ),
    );
    return (
      <section>
        <aside>
          <FileList
            fileIds={fileIds}
            files={files}
            heading={
              <React.Fragment>
                Select a File to Trim. When you are done, return to the
                <br />
                <strong> Upload Media </strong>
                tab to Submit for Processing.
              </React.Fragment>
            }
            onRefresh={loadAllFiles}
            onSelect={selectTrimFile}
            selectedId={fileToTrim}
          />
        </aside>
        {selectedFile && this.renderSelectedFile(selectedFile)}
      </section>
    );
  }

  renderSelectedFile(file) {
    const {
      preview_begin_seconds = DEFAULT_PREVIEW_SECONDS,
      preview_begin_url,
      preview_end_seconds = DEFAULT_PREVIEW_SECONDS,
      preview_end_url,
      trim_begin = 0,
      trim_end = 0,
      trim_end_subtract = 0,
      prefetched = true,
    } = file;
    const {
      markersInvalid,
      preview_begin,
      preview_begin_error,
      preview_end,
      preview_end_error,
    } = this.props;
    const loading_begin =
      preview_begin && !preview_begin_url && !preview_begin_error;
    const loading_end = preview_end && !preview_end_url && !preview_end_error;
    return (
      <React.Fragment>
        <span
          className={classNames("loading_spinner", {
            hide: prefetched,
          })}
        />
        {!prefetched && (
          <h2>File is being downloaded to server. Please wait.</h2>
        )}
        {prefetched && (
          <div>
            <div className="heading">
              Trim Footage
              <div className="action_links">
                {"OUT: "}
                <span>{Math.round(trim_end_subtract * 1000) / 1000}</span>
              </div>
              <div className="action_links">
                {"IN: "}
                <span>{Math.round(trim_begin * 1000) / 1000}</span>
              </div>
            </div>
            {(!!trim_begin || !!trim_end_subtract) && (
              <button onClick={this.onResetTrim} style={{ float: "right" }}>
                Reset Trim
              </button>
            )}
            <div className="input_block">
              <button
                className={classNames("btn_preview", { hide: loading_begin })}
                data-part="begin"
                onClick={this.onPreview}
              >
                Preview Starting
              </button>
              <span
                className={classNames("loading_spinner", {
                  hide: !loading_begin,
                })}
              />
              <input
                type="number"
                min="10"
                max="300"
                data-part="begin"
                placeholder="Start Length"
                value={preview_begin_seconds}
                onChange={this.onPreviewChangeSeconds}
              />{" "}
              seconds
            </div>
            {preview_begin &&
              this.renderSelectedPreviewPlayer(
                "begin",
                preview_begin_error,
                preview_begin_url,
                trim_begin,
                markersInvalid,
              )}
            <div className="input_block">
              <button
                className={classNames("btn_preview", { hide: loading_end })}
                data-part="end"
                onClick={this.onPreview}
              >
                Preview Ending
              </button>
              <span
                className={classNames("loading_spinner", {
                  hide: !loading_end,
                })}
              />
              <input
                type="number"
                min="10"
                max="300"
                data-part="end"
                placeholder="End Length"
                value={preview_end_seconds}
                onChange={this.onPreviewChangeSeconds}
              />{" "}
              seconds
            </div>
            {preview_end &&
              this.renderSelectedPreviewPlayer(
                "end",
                preview_end_error,
                preview_end_url,
                trim_end,
                markersInvalid,
              )}
          </div>
        )}
      </React.Fragment>
    );
  }

  renderSelectedPreviewPlayer(part, error, url, markedSeconds, markersInvalid) {
    if (error) {
      return (
        <div style={{ color: "red" }}>
          <strong>{error}</strong>
        </div>
      );
    }
    if (!url) return null;
    return (
      <PreviewPlayer
        marker={markedSeconds}
        markersInvalid={markersInvalid}
        onMarkTime={this.onMarkTime}
        part={part}
        url={url}
      />
    );
  }
}
