import formatBytes from "bytes";
import dprop from "dot-prop-immutable";
import { FileActions } from "../files/actions";
import { JoinerActions } from "../joiner/actions";
import { PublishingActions } from "../publishing/actions";
import { TrimmerActions } from "../trimmer/actions";
import { UploaderActions } from "../uploader/actions";

export const FilesState = {
  name: "files",
  persist: false,
  defaults: {
    /*
    [file.id]: file, ...
  */
  },
  handlers: {
    // FileActions
    [FileActions.FILE_ADD]: (state, action) => {
      return addFile(state, action.payload);
    },
    [FileActions.FILE_CANCEL_DELETE]: (state, action) => {
      const id = action.payload;
      return updateFile(state, id, {
        show_delete: undefined,
      });
    },
    [FileActions.FILE_DELETE]: (state, action) => {
      const id = action.payload;
      return deleteFile(state, id);
    },
    [FileActions.FILE_LOAD]: (state, action) => {
      return addFile(state, action.payload);
    },
    [FileActions.FILE_SHOW_DELETE]: (state, action) => {
      const id = action.payload;
      return updateFile(state, id, {
        show_delete: true,
      });
    },
    // JoinerActions
    [JoinerActions.JOIN_FILE_POLL]: (state, action) => {
      if (action.loading || action.payload.error) {
        return state;
      }
      action.payload.spinner = true;
      return addFile(state, action.payload);
    },
    [JoinerActions.JOIN_FILE_DONE]: (state, action) => {
      const { id, size } = action.payload;
      return updateFile(state, id, {
        ...action.payload,
        spinner: false,
        network_failed: false,
        displaySize: formatBytes(size),
      });
    },
    [JoinerActions.NETWORK_FAILED]: (state, action) => {
      const { id, error } = action.payload;
      return updateFile(state, id, {
        network_failed: true,
        err_message: error,
      });
    },
    [JoinerActions.JOIN_FAILED]: (state, action) => {
      const { id, error } = action.payload;
      return updateFile(state, id, {
        err_message: error,
        join_failed: true,
        spinner: false,
      });
    },
    // PublishingActions
    [PublishingActions.FILE_SUBMIT]: (state, action) => {
      const { fileId } = action.payload;
      return updateFile(state, fileId, {
        publishing_status: 1, // Submitting
      });
    },
    [PublishingActions.FILE_SUBMIT_OK]: (state, action) => {
      const { fileId } = action.payload;
      return updateFile(state, fileId, {
        publishing_status: 2, // Published
      });
    },
    // TrimmerActions
    [TrimmerActions.TRIM_FILE_MARK]: (state, action) => {
      const { id, part, seconds } = action.payload;
      const {
        [id]: { preview_end_duration },
      } = state;
      const update = {
        [`trim_${part}`]: seconds,
      };
      if (part === "end") {
        // The amount of time being deducted from the end of the original media.
        update.trim_end_subtract = -(preview_end_duration - seconds);
      }
      return updateFile(state, id, update);
    },
    [TrimmerActions.TRIM_FILE_PREVIEW_LOAD]: (state, action) => {
      const { id, part } = action.payload;
      return updateFile(state, id, {
        [`preview_${part}_url`]: "",
      });
    },
    [TrimmerActions.TRIM_FILE_PREVIEW]: (state, action) => {
      const {
        id,
        part,
        seconds,
        duration,
        url,
        originalDuration,
      } = action.payload;
      const keyPrefix = "preview_" + part + "_";
      return updateFile(
        state,
        id,
        {
          duration: originalDuration,
          [keyPrefix + "duration"]: duration,
          [keyPrefix + "seconds"]: seconds,
          [keyPrefix + "url"]: url,
        },
        file =>
          dprop.set(file, `previews.${part}_${seconds}`, {
            duration,
            url,
          }),
      );
    },
    [TrimmerActions.TRIM_FILE_PREVIEW_CHANGE]: (state, action) => {
      const { id, part, seconds } = action.payload;
      const keyPrefix = "preview_" + part + "_";
      return updateFile(state, id, {
        [keyPrefix + "seconds"]: seconds,
        [keyPrefix + "url"]: undefined,
      });
    },
    [TrimmerActions.TRIM_FILE_RESET]: (state, action) => {
      const id = action.payload;
      return updateFile(state, id, {
        trim_begin: undefined,
        trim_end: undefined,
        trim_end_subtract: undefined,
      });
    },
    [TrimmerActions.PREFETCHING]: (state, action) => {
      const { id } = action.payload;
      return updateFile(state, id, {
        prefetched: false,
        spinner: true,
      });
    },
    [TrimmerActions.PREFETCHED]: (state, action) => {
      const { id } = action.payload;
      return updateFile(state, id, {
        prefetched: true,
        spinner: false,
      });
    },
    // UploaderActions
    [UploaderActions.UPLOAD_CANCEL]: (state, action) => {
      const id = action.payload;
      return deleteFile(state, id);
    },
    [UploaderActions.PREFETCHING]: (state, action) => {
      const { id } = action.payload;
      return updateFile(state, id, {
        prefetched: false,
        spinner: true,
      });
    },
    [UploaderActions.PREFETCH_DOWNLOAD_PROGRESS]: (state, action) => {
      const { id, downloadProgress = 0 } = action.payload;
      return updateFile(state, id, {
        downloadProgress,
      });
    },
    [UploaderActions.PREFETCHED]: (state, action) => {
      const { id } = action.payload;
      return updateFile(state, id, {
        prefetched: true,
        spinner: false,
      });
    },
    [UploaderActions.CORRUPT]: (state, action) => {
      const {
        id,
        response: { is_corrupt, err_message },
      } = action.payload;
      return updateFile(state, id, {
        is_corrupt,
        err_message,
      });
    },
    [UploaderActions.UPLOAD_COMPLETE]: (state, action) => {
      const { id, update } = action.payload;
      return updateFile(state, id, {
        upload_completed: true,
        upload_url: undefined,
        upload_headers: undefined,
        ...update,
      });
    },
    [UploaderActions.UPLOAD_FAILED]: (state, action) => {
      const id = action.payload;
      return updateFile(state, id, {
        upload_failed: true,
        upload_url: undefined,
        upload_headers: undefined,
      });
    },
    [UploaderActions.UPLOAD_PROGRESS]: (state, action) => {
      const { id, upload_progress, completing = false } = action.payload;
      return updateFile(state, id, {
        upload_progress: completing ? upload_progress : (parseFloat(upload_progress) * .95).toFixed(1) + " %",
      });
    },
    [UploaderActions.UPLOAD_RESUME]: (state, action) => {
      const id = action.payload;
      return updateFile(state, id, {
        multipart_last: undefined,
        multipart_missing: undefined,
        multipart_sizes: undefined,
        show_resume: undefined,
      });
    },
  },
};

/** @param {{id: string, size: number} file */
function addFile(state, file) {
  const { id, size } = file;
  return {
    ...state,
    [id]: {
      upload_progress: "",
      ...file,
      displaySize: formatBytes(size),
    },
  };
}

function deleteFile(state, id) {
  const { [id]: fileToDelete, ...filesThatAreNotBeingDeleted } = state;
  return filesThatAreNotBeingDeleted;
}

/** @param {object} state
 * @param {string} id
 * @prop {{[x:string]: object}} propsToSet
 */
function updateFile(state, id, propsToSet, mapFileState = file => file) {
  const { [id]: fileWithId, ...filesThatAreNotBeingUpdated } = state;
  return {
    ...filesThatAreNotBeingUpdated,
    [id]: mapFileState({
      ...fileWithId,
      ...propsToSet,
    }),
  };
}
