import { UploadsAPI } from "../../api";
import { FileSelectors } from "../files/selectors";
import { sortObjectKeysByNameAndId } from "../../lib";

function pollJoinStatus(id, dispatch) {
  UploadsAPI.getUpload(id)
    .then(status => {
      if (status.upload_completed) {
        dispatch({
          type: JoinerActions.JOIN_FILE_DONE,
          payload: status,
        });
        return;
      } else {
        setTimeout(() => {
          pollJoinStatus(id, dispatch);
        }, 5000);
      }
    })
    .catch(err => {
      if (err.response && err.response.status === 404) {
        dispatch({
          type: JoinerActions.JOIN_FAILED,
          payload: {
            id,
            error: err ? err.toString() : "Unknown Error.",
          },
        });
        return;
      }
      dispatch({
        type: JoinerActions.NETWORK_FAILED,
        payload: {
          id,
          error: err ? err.toString() : "Unknown Error.",
        },
      });
      setTimeout(() => {
        pollJoinStatus(id, dispatch);
      }, 30000);
    });
}

export const JoinerActions = {
  JOIN_FILE_CHANGE: "JOIN_FILE_CHANGE",
  JOIN_FILE_CREATE: "JOIN_FILE_CREATE",
  JOIN_FILE_POLL: "JOIN_FILE_POLL",
  JOIN_FILE_DONE: "JOIN_FILE_DONE",
  NETWORK_FAILED: "NETWORK_FAILED",
  JOIN_FAILED: "JOIN_FAILED",
  JOIN_FILE_SELECT: "JOIN_FILE_SELECT",
  JOIN_FILE_SORT: "JOIN_FILE_SORT",

  changeJoinFile(fileName) {
    return { type: JoinerActions.JOIN_FILE_CHANGE, payload: fileName };
  },
  createJoinFile() {
    return async (dispatch, getState) => {
      const state = getState();
      let {
        joiner: {
          filesToJoin,
          joiningToFileName: name = "",
          sortedFileIds,
          allowFileIds,
        },
        files,
      } = state;
      // Make sure we always have the files sorted correctly in alphabetical order additional
      const sortAllowFileIds = sortObjectKeysByNameAndId(
        files,
        allowFileIds.filter(id => files[id]),
      );
      let ids = sortedFileIds.length
        ? sortedFileIds.filter(id => filesToJoin[id])
        : sortAllowFileIds.filter(id => filesToJoin[id]);

      name = name.trim();
      if (ids.length < 2) {
        dispatch({
          type: JoinerActions.JOIN_FILE_CREATE,
          payload: {
            error: "Please select 2 or more files.",
          },
        });
        return;
      } else if (name.length < 1) {
        dispatch({
          type: JoinerActions.JOIN_FILE_CREATE,
          payload: {
            error: "Please enter a new file name.",
          },
        });
        return;
      }
      dispatch({ type: JoinerActions.JOIN_FILE_CREATE, loading: true });
      const created = await UploadsAPI.createJoin({
        ids,
        name,
      }).catch(err => {
        return {
          error: "Server error. Tech support has been notified.",
        };
      });
      dispatch({
        type: JoinerActions.JOIN_FILE_POLL,
        payload: created,
      });
      pollJoinStatus(created.id, dispatch);
    };
  },
  /** @param {string} id */
  selectJoinFile(id) {
    return (dispatch, getState) => {
      if (FileSelectors.isFilePublishedOrPublishing(id, getState())) {
        return;
      }
      if (!FileSelectors.isFileUploadCompleted(id, getState())) {
        return;
      }
      dispatch({ type: JoinerActions.JOIN_FILE_SELECT, payload: id });
    };
  },
  /** @param {string[]} sortedFileIds */
  sortJoinFile(sortedFileIds) {
    return { type: JoinerActions.JOIN_FILE_SORT, payload: sortedFileIds };
  },
};
