import { FileActions } from "../files/actions";
import { UploaderActions } from "./actions";
import { PublishingActions } from "../publishing/actions";

export const UploaderState = {
  name: "uploader",
  persist: false,
  defaults: {
    loading: false,
    loadingUsers: false,
    loadingUsersWithFiles: false,
    selectedUpload: "",
    users: undefined,
    usersWithFiles: undefined,
  },
  handlers: {
    [FileActions.FILE_DELETE]: (state, action) => {
      if (action.payload !== state.selectedUpload) {
        return state;
      }
      return {
        ...state,
        selectedUpload: "",
      };
    },
    [FileActions.FILES_LOAD_ALL]: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    [PublishingActions.FILE_SUBMIT]: (state, action) => {
      if (state.selectedUpload !== action.payload.fileId) {
        return state;
      }
      // The selected file is being submitted. Unselect it now.
      return {
        ...state,
        selectedUpload: "",
      };
    },
    [UploaderActions.UPLOAD_SELECT]: (state, action) => {
      const selectedUpload =
        state.selectedUpload === action.payload ? "" : action.payload;
      return {
        ...state,
        selectedUpload,
      };
    },
    [UploaderActions.UPLOAD_USERS_GET]: (state, action) => {
      const { loading, payload } = action;
      return {
        ...state,
        loadingUsers: loading,
        users: payload,
      };
    },
    [UploaderActions.UPLOAD_USERS_WITH_FILES_GET]: (state, action) => {
      const { loading, payload } = action;
      return {
        ...state,
        loadingUsersWithFiles: loading,
        usersWithFiles: payload,
      };
    },
  },
};
