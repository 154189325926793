import React from "react";
import classNames from "classnames";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import {
  closestAttribs,
  closestAttribVal,
  isAudioFile,
  isVideoFile,
} from "../lib";

function FileListHeader({ heading, onRefresh }) {
  return (
    <div className="filelist_header">
      <div id="refresh_filelist" className="action_links" onClick={onRefresh}>
        Refresh List
      </div>
      {heading}
    </div>
  );
}

function FileListItem({
  file,
  onCancelDelete,
  onCancelUpload,
  onDelete,
  onResumeUpload,
  onSelect,
  onShowDelete,
  sortable,
  selectedId,
  selectedIds,
}) {
  if (!file) {
    return null;
  }
  const {
    id,
    name,
    publishing_status,
    upload_completed,
    upload_failed,
    join_failed,
    network_failed,
    displaySize,
    upload_progress,
    show_delete = upload_failed,
    show_resume,
    trim_begin,
    trim_end_subtract,
    is_corrupt,
    err_message = "",
    spinner = false,
    downloadProgress,
  } = file;
  if (!id) {
    return null;
  }
  const isAudio = isAudioFile(name);
  const isVideo = isVideoFile(name);
  const isTrimmed = trim_begin || trim_end_subtract;
  // #region Event Handlers
  /** @param {React.MouseEvent<HTMLSpanElement>} e */
  function onClickCancelDelete(e) {
    const {
      "data-id": { value: id },
    } = closestAttribs(e.currentTarget.parentElement, "data-id");
    e.stopPropagation();
    onCancelDelete(id);
  }
  /** @param {React.MouseEvent<HTMLButtonElement>} e */
  function onClickCancelUpload(e) {
    const id = closestAttribVal(e.currentTarget.parentElement, "data-id");
    e.stopPropagation();
    onCancelUpload(id);
  }
  /** @param {React.MouseEvent<HTMLSpanElement>} e */
  function onClickDelete(e) {
    const {
      "data-id": { value: id },
    } = closestAttribs(e.currentTarget.parentElement, "data-id");
    e.stopPropagation();
    onDelete(id);
  }
  /** @param {React.MouseEvent<HTMLButtonElement>} e */
  function onClickResumeUpload(e) {
    const id = closestAttribVal(e.currentTarget.parentElement, "data-id");
    e.stopPropagation();
    onResumeUpload(id);
  }
  /** @param {React.MouseEvent<HTMLDivElement>} e */
  function onClickSelect(e) {
    if (!onSelect) {
      return;
    }
    const id = closestAttribVal(e.currentTarget, "data-id");
    onSelect(id);
  }

  /** @param {React.MouseEvent<HTMLSpanElement>} e */
  function onClickShowDelete(e) {
    const {
      "data-id": { value: id },
    } = closestAttribs(e.currentTarget.parentElement, "data-id");
    e.stopPropagation();
    onShowDelete(id);
  }
  // #endregion
  return (
    <div
      className={classNames("file_item", {
        active: id === selectedId || (selectedIds && id in selectedIds),
        buttons_visible: show_delete || !upload_completed,
        error: upload_failed || is_corrupt || network_failed || join_failed,
        sortable: sortable && !publishing_status,
      })}
      data-id={id}
      onClick={onClickSelect}
    >
      <i className="material-icons">
        {classNames({
          audiotrack: isAudio,
          videocam: isVideo,
          error: (!isAudio && !isVideo) || is_corrupt,
        })}
      </i>
      <span className="filename">
        {name}
        {upload_failed && <span className="error">Upload failed!</span>}
        {join_failed && <span className="error">Join failed!</span>}
        {(is_corrupt || network_failed) && (
          <div className="error">{err_message}</div>
        )}
      </span>
      {isTrimmed && (
        <i className="material-icons" title="Trimmed">
          content_cut
        </i>
      )}
      <span className="filesize">
        {upload_completed
          ? displaySize
          : upload_failed
          ? ""
          : upload_progress + " "}
      </span>
      {!!downloadProgress && !upload_completed && (
        <span style={{ maxWidth: 30 }} className="filesize">
          {parseInt(downloadProgress)}%
        </span>
      )}
      {publishing_status === 1 && <span className="icon_processing" />}
      {spinner === true && <span className="icon_processing" />}
      {publishing_status === 2 && (
        <span className="icon_processing icon_done" />
      )}
      {onDelete && (upload_completed || upload_failed) && !publishing_status && (
        <React.Fragment>
          {show_delete && (
            <div className="confirm_del" onClick={onClickDelete}>
              {upload_failed ? "X" : "Delete!"}
            </div>
          )}
          {!upload_failed && !join_failed && (
            <span
              className={classNames("icon_del", { cancel: show_delete })}
              onClick={show_delete ? onClickCancelDelete : onClickShowDelete}
            />
          )}
        </React.Fragment>
      )}
      {onResumeUpload && show_resume && !upload_completed && !upload_failed && (
        <button className="resume_upload" onClick={onClickResumeUpload}>
          Resume
        </button>
      )}
      {onCancelUpload && !upload_completed && !upload_failed && (
        <button className="cancel_upload" onClick={onClickCancelUpload}>
          Cancel
        </button>
      )}
    </div>
  );
}

export function FileList({
  fileIds,
  files,
  heading,
  onRefresh,
  onSort,
  selectedId,
  selectedIds,
  ...itemEvents
}) {
  function onSortEnd({ oldIndex, newIndex }) {
    onSort(arrayMove(fileIds, oldIndex, newIndex));
  }
  return (
    <React.Fragment>
      <FileListHeader heading={heading} onRefresh={onRefresh} />
      {onSort ? (
        <SortableList
          distance={10}
          fileIds={fileIds}
          files={files}
          selectedId={selectedId}
          selectedIds={selectedIds}
          sortable={true}
          onSortEnd={onSortEnd}
          {...itemEvents}
        />
      ) : (
        <div id="filelist">
          {fileIds.map(id => (
            <FileListItem
              key={id}
              file={files[id]}
              selectedId={selectedId}
              selectedIds={selectedIds}
              {...itemEvents}
            />
          ))}
        </div>
      )}
    </React.Fragment>
  );
}

const SortableItem = SortableElement(FileListItem);

const SortableList = SortableContainer(
  ({ fileIds, files, selectedIds, sortable, ...itemEvents }) => {
    return (
      <div id="filelist">
        {fileIds.map((id, index) => (
          <SortableItem
            key={id}
            disabled={!sortable}
            index={index}
            file={files[id]}
            selectedIds={selectedIds}
            sortable={sortable}
            {...itemEvents}
          />
        ))}
      </div>
    );
  },
);
