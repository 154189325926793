import "babel-polyfill";
import "material-icons";
import "typeface-assistant";
import "react-tabs/style/react-tabs.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./assets/css/styles.scss";

import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";

import { AuthAPI, ParentWindowAPI } from "./api";
import { ErrorSentry } from "./components";
import { FileActions, UserActions, activateStore, store } from "./state";
import App from "./App.vm";

async function main() {
  ParentWindowAPI.init();
  await activateStore();
  const user = await AuthAPI.getSignedInUser();
  if (user) {
    ErrorSentry.setUser(user);
    store.dispatch(UserActions.signedIn(user));
  } else {
    const msg =
      "You are not authorized to use the uploader." +
      (process.env.NODE_ENV !== "development"
        ? ""
        : "\n\n" + AuthAPI.authTokenMissingMessage);
    window.alert(msg);
    // CONSIDER: The following redirect was for WWW not react-admin. Maybe we
    // should post a message to react-admin to close the panel?
    // ParentWindowAPI.redirectToHome();
    return;
  }
  store.dispatch(FileActions.loadAllFiles());
  render();
}

function render() {
  ReactDOM.render(
    <ErrorSentry>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </ErrorSentry>,
    document.getElementById("root"),
  );
}

main();
