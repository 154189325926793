import { UploadsAPI } from "../../api";
import { FileSelectors } from "../files/selectors";
import { toast } from "react-toastify";

const MIN_DURATION = parseInt(
  process.env.REACT_APP_UPLOAD_MEDIA_MIN_DURATION || 3,
  10,
);

export const TrimmerActions = {
  TRIM_FILE_MARK: "TRIM_FILE_MARK",
  TRIM_FILE_MARK_INVALID: "TRIM_FILE_MARK_INVALID",
  TRIM_FILE_PREVIEW: "TRIM_FILE_PREVIEW",
  TRIM_FILE_PREVIEW_CHANGE: "TRIM_FILE_PREVIEW_CHANGE",
  TRIM_FILE_PREVIEW_LOAD: "TRIM_FILE_PREVIEW_LOAD",
  TRIM_FILE_SELECT: "TRIM_FILE_SELECT",
  TRIM_FILE_RESET: "TRIM_FILE_RESET",
  PREFETCHING: "PREFETCHING",
  PREFETCHED: "PREFETCHED",

  changeTrimFilePreview(id, part, seconds) {
    return {
      type: TrimmerActions.TRIM_FILE_PREVIEW_CHANGE,
      payload: {
        id,
        part,
        seconds,
      },
    };
  },

  markTrimFile(id, part, seconds) {
    return async (dispatch, getState) => {
      const state = getState();
      const {
        files: {
          [id]: { preview_end_duration },
        },
      } = state;
      if (!markerIsValid(state, id, part, seconds)) {
        dispatch({ type: TrimmerActions.TRIM_FILE_MARK_INVALID });
        return;
      }
      dispatch({
        type: TrimmerActions.TRIM_FILE_MARK,
        payload: {
          id,
          part,
          seconds,
        },
      });
      const params = {
        part,
        seconds,
      };
      if (part === "end") {
        params.subtract = -(preview_end_duration - seconds);
      }
      await UploadsAPI.createTrim(id, params);
    };
  },

  previewTrimFile(id, part, seconds) {
    return async (dispatch, getState) => {
      const {
        files: {
          [id]: {
            duration: originalDuration = 0,
            previews: { [`${part}_${seconds}`]: existingPreview } = {},
          },
        },
      } = getState();
      dispatch({
        type: TrimmerActions.TRIM_FILE_PREVIEW_LOAD,
        payload: {
          id,
          part,
          originalDuration,
        },
      });
      // in case of timeout error where we store existingPreview's url as undefined (Line 112 - 114), try again to get it from server
      if (existingPreview && existingPreview.url) {
        dispatch({
          type: TrimmerActions.TRIM_FILE_PREVIEW,
          payload: {
            // PARAMS
            id,
            part,
            seconds,
            // RESPONSE
            duration: existingPreview.duration,
            url: existingPreview.url,
            originalDuration,
          },
        });
        return;
      }
      const {
        error,
        duration,
        url,
        originalDuration: originalDurationFromServer,
      } = await UploadsAPI.createPreview(id, {
        originalDuration,
        part,
        seconds,
      }).catch(err => {
        // console.log('TRANCODER ERROR: ', err.response);
        // const {
        //   // code,
        //   message,
        // } = err.response.data;
        return {
          error: "Server error. Tech support has been notified.",
          url: undefined,
        };
      });
      dispatch({
        type: TrimmerActions.TRIM_FILE_PREVIEW,
        payload: {
          // PARAMS
          id,
          part,
          seconds,
          // RESPONSE
          duration,
          url,
          error,
          originalDuration: originalDurationFromServer,
        },
      });
    };
  },
  resetTrimFile(id) {
    return async dispatch => {
      await UploadsAPI.resetTrim(id);
      dispatch({ type: TrimmerActions.TRIM_FILE_RESET, payload: id });
    };
  },
  selectTrimFile(id) {
    return (dispatch, getState) => {
      if (FileSelectors.isFilePublishedOrPublishing(id, getState())) {
        return;
      }
      dispatch({ type: TrimmerActions.TRIM_FILE_SELECT, payload: id });
      isPrefetched(id, dispatch, getState);
    };
  },
};
function markerIsValid(state, id, part, seconds) {
  let {
    files: {
      [id]: { duration, preview_end_duration, trim_begin, trim_end_subtract },
    },
  } = state;
  if (part === "begin") {
    trim_begin = seconds;
  } else {
    trim_end_subtract = -(preview_end_duration - seconds);
  }
  if (!trim_begin || !trim_end_subtract) {
    return true;
  }
  const trimmed_duration = duration - trim_begin + trim_end_subtract;
  if (trimmed_duration > MIN_DURATION) {
    return true;
  }
  return false;
}
function isPrefetched(id, dispatch, getState) {
  const state = getState();
  const { files } = state;
  const fileInState = files[id];
  if (!fileInState) {
    return;
  }
  if (fileInState.prefetched) {
    return;
  }
  dispatch({
    type: TrimmerActions.PREFETCHING,
    payload: { id },
  });
  UploadsAPI.getPrefetchedStatus(id)
    .then(res => {
      const { exists, isStillDownloading } = res.data;
      if (exists && !isStillDownloading) {
        dispatch({
          type: TrimmerActions.PREFETCHED,
          payload: { id },
        });
        return;
      } else if (exists) {
        setTimeout(() => {
          isPrefetched(id, dispatch, getState);
        }, 15000);
      } else {
        // Handle case when file !exists
        toast.error("Oops! we could not find your file! Please refresh!");
      }
    })
    .catch(console.error);
}
