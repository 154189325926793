const audiotypes = ["mp3", "m4a", "wav", "wma", "aac"];
const videotypes = ["mp4", "mpg", "mov", "vob", "avi", "wmv"];

/** Converts an array of objects to an object with a key per id.
 * @param {{id: string}[]} arr */
export function arrayToObjectById(arr, mapItem = item => item) {
  const result = {};
  arr.forEach(item => {
    result[item.id] = mapItem(item);
  });
  return result;
}
/** @param {string} filename */
export function isAudioFile(filename) {
  const filetype = filename.substr(-3).toLowerCase();
  return audiotypes.indexOf(filetype) > -1;
}

/** @param {string} filename */
export function isVideoFile(filename) {
  const filetype = filename.substr(-3).toLowerCase();
  return videotypes.indexOf(filetype) > -1;
}
/** Returns the given objects keys sorted by name+id.
 * @param {{[x:string]: {id:string, name:string}}} data
 * @param {string[]} fromKeys
 */
export function sortObjectKeysByNameAndId(data, fromKeys) {
  const keys = fromKeys || Object.keys(data);
  return keys.sort((a, b) => {
    // Avoid errors with missing keys.
    if (!a) {
      return !b ? 0 : -1;
    }
    if (!b) {
      return 1;
    }
    // Get data.
    const itemA = data[a];
    const itemB = data[b];
    // Avoid errors with missing data.
    if (!itemA) {
      return !itemB ? 0 : -1;
    }
    if (!itemB) {
      return 1;
    }
    // Compare
    return ("" + itemA.name + itemA.id).localeCompare(
      "" + itemB.name + itemB.id,
    );
  });
}
