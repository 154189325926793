import { UserActions } from "./actions";

export const UserState = {
  name: "user",
  persist: false,
  defaults: {
    email: "",
    id: "",
    name_first: "",
    name_last: "",
    upload_zone: "sfo2",
  },
  handlers: {
    [UserActions.USER_SIGNIN_COMPLETE]: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};
