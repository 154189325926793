import { PublishingActions, store as reduxStore } from "../state";

const __DEV__ = process.env.NODE_ENV === "development";
const PARENT_APP_URL = process.env.REACT_APP_PARENT_URL;

const Actions = {
  //   active_uploads: "active_uploads",
  //   create_lecture: "create_lecture",
  //   editing_lecture: "editing_lecture",
  init: "init",
  publish_lecture: "publish_lecture",
  // save_lecture: "save_lecture",
  // saved_lecture: "saved_lecture",
  selected_upload: "selected_upload",
};

const hasParentWindow = window !== window.top;
const parentWindow = window.parent;

function consoleLogDev(...args) {
  if (!__DEV__) {
    return;
  }
  console.log(...args);
}

function listenForMessageFromParentApp() {
  window.addEventListener("message", onWindowMessage);
}

function maybeNavigateDevToTestIframe() {
  if (!__DEV__) {
    return;
  }
  setTimeout(() => {
    if (!window.confirm("Go to /test_iframe.html to simulate parent app?")) {
      return;
    }
    window.location = "/test_iframe.html";
  }, 3000);
}

/**
 * Handler for window `message` event.
 * Ported from original code in ta3-uploader/gallery.php#L244.
 * @param {MessageEvent} e
 */
function onWindowMessage(e) {
  if (PARENT_APP_URL.indexOf(e.origin) !== 0) {
    return;
  }
  const { action } = e.data;
  const handler = ParentAppActionHandlers[action];
  if (!handler) {
    return;
  }
  consoleLogDev("ParentWindowAPI handle", e.data);
  handler(e.data);
}

function postParentMessage(data) {
  console.log("parentWindow.postMessage: ", PARENT_APP_URL, data);
  parentWindow.postMessage(data, PARENT_APP_URL);
}

const ParentAppActionHandlers = {
  [Actions.publish_lecture](data) {
    const { lectureId } = data;
    reduxStore.dispatch(PublishingActions.publishLecture({ lectureId }));
  },
};

export const ParentWindowAPI = {
  // createLecture() {
  // Not handled by beta site:
  // consoleLogDev("REQUESTING create_lecture");
  // postParentMessage({ action: Actions.create_lecture });
  // },
  get hasParentWindow() {
    return hasParentWindow;
  },
  init() {
    // NOTE: If init needs access to redux store state, then the call to
    // `ParentWindowAPI.init()` should be moved below `activateStore()` in the
    // index.js `main()` function.
    consoleLogDev("ParentWindowAPI.hasParentWindow: ", hasParentWindow);
    if (!hasParentWindow) {
      maybeNavigateDevToTestIframe();
      return;
    }
    listenForMessageFromParentApp();
    postParentMessage({
      action: Actions.init,
    });
  },
  redirectToHome() {
    if (hasParentWindow) {
      parentWindow.location.replace("/");
    } else {
      window.location.replace("/");
    }
  },
  /**
   * @param {{name:string}} fileInfo File info.
   */
  selectedUpload(fileInfo) {
    let file = null;
    if (fileInfo) {
      const { id, size, mime_type, name, user_device_id } = fileInfo;
      const ext = (name + "").split(".").reverse()[0];
      file = {
        id,
        ext,
        mime_type,
        name,
        size,
        user_device_id,
      };
    }
    postParentMessage({
      action: Actions.selected_upload,
      file,
    });
  },
  activeUploads(count) {
    // Not handled by beta site:
    // postParentMessage({
    //   action: Actions.active_uploads,
    //   count,
    // });
  },
};
