import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { UploaderActions } from "../state";
import { TransferFromUserModal as View } from "./TransferFromUserModal";

export const TransferFromUserModal = connect(
  function mapState(state) {
    const {
      uploader: { loadingUsersWithFiles, usersWithFiles },
    } = state;
    return {
      loadingUsersWithFiles,
      usersWithFiles,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: {
        ...bindActionCreators(UploaderActions, dispatch),
      },
    };
  },
)(View);
