import { FilesState } from "./files/state";
import { JoinerState } from "./joiner/state";
import { PublishingState } from "./publishing/state";
import { TrimmerState } from "./trimmer/state";
import { UIState } from "./ui/state";
import { UploaderState } from "./uploader/state";
import { UserState } from "./user/state";

export * from "./files/actions";
export * from "./joiner/actions";
export * from "./publishing/actions";
export * from "./trimmer/actions";
export * from "./ui/actions";
export * from "./uploader/actions";
export * from "./user/actions";
export * from "./user/selectors";

export * from "./files/selectors";

export default [
  FilesState,
  JoinerState,
  PublishingState,
  TrimmerState,
  UIState,
  UploaderState,
  UserState,
];
