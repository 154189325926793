import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { UIActions } from "./state";
import App from "./App";

export default connect(
  function mapState(state) {
    const { ui: { mainTabIndex = 0 } = {} } = state;
    return {
      mainTabIndex,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          selectTab: UIActions.selectTab,
        },
        dispatch,
      ),
    };
  },
)(App);
