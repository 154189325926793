import React from "react";
import {
  init as Sentry_init,
  configureScope as Sentry_configureScope,
  withScope as Sentry_withScope,
  captureException as Sentry_captureException,
  showReportDialog as Sentry_showReportDialog,
} from "@sentry/browser";

const { NODE_ENV = "development", REACT_APP_VERSION = "0.0.0" } = process.env;

const __DEV__ = NODE_ENV === "development";

/** See https://docs.sentry.io/learn/configuration */
Sentry_init({
  /** Do not send errors in development. */
  beforeSend: __DEV__
    ? function() {
        return null;
      }
    : undefined,
  /** Where to send errors. This is NOT a secret. */
  dsn: "https://49aa69dab2c54ea897f512923f9060c5@sentry.io/1308507",
  environment: NODE_ENV,
  release: REACT_APP_VERSION,
});

export class ErrorSentry extends React.Component {
  static setUser(user) {
    Sentry_configureScope(scope => {
      scope.setUser({
        email: user.email,
        id: user.id,
        username: `${user.name_first} ${user.name_last}`,
      });
    });
  }

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry_withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry_captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <a href="/" onClick={this.showReportDialog}>
          Report feedback
        </a>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }

  showReportDialog = e => {
    e.preventDefault();
    Sentry_showReportDialog();
  };
}
