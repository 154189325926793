import React from "react";
import { FileList } from "../components";
import {
  handleEnterKeyPress,
  handleTargetValue,
  sortObjectKeysByNameAndId,
} from "../lib";

export class Joiner extends React.PureComponent {
  onChangeJoinFile = handleTargetValue(this.props.actions.changeJoinFile);
  onJoinFileEnterKeyPress = handleEnterKeyPress(
    this.props.actions.createJoinFile,
  );

  render() {
    const {
      actions: { createJoinFile, loadAllFiles, selectJoinFile, sortJoinFile },
      allowFileIds,
      error,
      files,
      filesToJoin,
      joiningToFileName,
      loading,
      sortedFileIds,
    } = this.props;
    const fileIds =
      sortedFileIds.length > 0
        ? // Don't allow joins on uploads that didn't finish uploading
          sortedFileIds.filter(
            id =>
              files[id] &&
              (files[id].upload_completed || files[id].spinner) &&
              !files[id].is_corrupt,
          )
        : sortObjectKeysByNameAndId(
            files,
            allowFileIds
              // Don't allow joins on uploads that didn't finish uploading
              .filter(
                id =>
                  files[id] &&
                  (files[id].upload_completed || files[id].spinner) &&
                  !files[id].is_corrupt,
              ),
          );
    return (
      <section>
        <aside>
          <FileList
            fileIds={fileIds}
            files={files}
            heading={
              <React.Fragment>
                Select the files you want to join.
                <br />
                You can also drag them to order them.
              </React.Fragment>
            }
            onRefresh={loadAllFiles}
            onSelect={selectJoinFile}
            onSort={sortJoinFile}
            selectedIds={filesToJoin}
          />
        </aside>
        {loading ? (
          <span className="loading_spinner" />
        ) : (
          <div className="input_block">
            <input
              id="join_newname"
              type="text"
              placeholder="Enter a new file name"
              value={joiningToFileName}
              onChange={this.onChangeJoinFile}
              onKeyPress={this.onJoinFileEnterKeyPress}
            />
            <span> .MP4 </span>
            <button onClick={createJoinFile}>Join files</button>
          </div>
        )}
        {error && (
          <div style={{ color: "red" }}>
            <strong>{error}</strong>
          </div>
        )}
      </section>
    );
  }
}
