import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  FileActions,
  PublishingActions,
  UIActions,
  UploaderActions,
} from "../state";
import { Uploader as View } from "./Uploader";

export const Uploader = connect(
  function mapState(state) {
    const {
      files,
      uploader: { loading, selectedUpload },
      ui: { networkSpeed, verifyContentHashDisabled },
      user: {
        role: { administrator: userIsAdmin = false },
        upload_zone,
      },
    } = state;
    return {
      files,
      loading,
      networkSpeed,
      selectedUpload,
      userIsAdmin,
      verifyContentHashDisabled,
      upload_zone,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: {
        ...bindActionCreators(FileActions, dispatch),
        ...bindActionCreators(PublishingActions, dispatch),
        ...bindActionCreators(UploaderActions, dispatch),
        ...bindActionCreators(UIActions, dispatch),
      },
    };
  },
)(View);
