/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import { ToastContainer } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { Joiner, Trimmer, Uploader, UrlUploader } from "./screens";

class App extends React.PureComponent {
  render() {
    const {
      props: {
        actions: { selectTab },
        mainTabIndex,
      },
    } = this;
    return (
      <React.Fragment>
        <Tabs onSelect={selectTab} selectedIndex={mainTabIndex}>
          <TabList className="tool_header">
            <Tab>
              <i className="material-icons">cloud_upload</i> Upload Media
            </Tab>
            <Tab>
              <i className="material-icons">content_cut</i> Trim
            </Tab>
            <Tab>
              <i className="material-icons">movie</i> Join Videos
            </Tab>
            <Tab>
              <i className="material-icons">cloud_upload</i> Url
            </Tab>
          </TabList>
          <TabPanel>
            <Uploader />
          </TabPanel>
          <TabPanel>
            <Trimmer />
          </TabPanel>
          <TabPanel>
            <Joiner />
          </TabPanel>
          <TabPanel>
            <UrlUploader />
          </TabPanel>
        </Tabs>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
export default App;
