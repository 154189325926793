/** @file Copy of p-queue [1,2] since they don't transpile for browser [3].
 * - [1] https://www.npmjs.com/package/p-queue
 * - [2] https://github.com/sindresorhus/p-queue
 * - [3] https://github.com/sindresorhus/p-queue/issues?utf8=%E2%9C%93&q=is%3Aissue+browser
 * - Also added inline documentation.
 */
// Port of lower_bound from http://en.cppreference.com/w/cpp/algorithm/lower_bound
// Used to compute insertion index to keep queue sorted after insertion
function lowerBound(array, value, comp) {
  let first = 0;
  let count = array.length;

  while (count > 0) {
    const step = (count / 2) | 0;
    let it = first + step;

    if (comp(array[it], value) <= 0) {
      first = ++it;
      count -= step + 1;
    } else {
      count = step;
    }
  }

  return first;
}

class PriorityQueue {
  constructor() {
    this._queue = [];
  }

  enqueue(run, opts) {
    opts = Object.assign(
      {
        priority: 0,
      },
      opts,
    );

    const element = { priority: opts.priority, run };

    if (this.size && this._queue[this.size - 1].priority >= opts.priority) {
      this._queue.push(element);
      return;
    }

    const index = lowerBound(
      this._queue,
      element,
      (a, b) => b.priority - a.priority,
    );
    this._queue.splice(index, 0, element);
  }

  dequeue() {
    return this._queue.shift().run;
  }

  get size() {
    return this._queue.length;
  }
}
/** Useful for rate-limiting async (or sync) operations. For example, when
 * interacting with a REST API or when doing CPU/memory intensive tasks.
 * From https://github.com/sindresorhus/p-queue
 */
export class PQueue {
  constructor(opts) {
    opts = Object.assign(
      {
        concurrency: Infinity,
        autoStart: true,
        queueClass: PriorityQueue,
      },
      opts,
    );

    if (!(typeof opts.concurrency === "number" && opts.concurrency >= 1)) {
      throw new TypeError(
        `Expected \`concurrency\` to be a number from 1 and up, got \`${
          opts.concurrency
        }\` (${typeof opts.concurrency})`,
      );
    }

    this.queue = new opts.queueClass(); // eslint-disable-line new-cap
    this.concurrency = opts.concurrency;
    this._queueClass = opts.queueClass;
    this._pendingCount = 0;
    this._isPaused = opts.autoStart === false;
    this._resolveEmpty = () => {};
    this._resolveIdle = () => {};
  }
  /** Internal function, do not use. */
  _next() {
    this._pendingCount--;

    if (this.queue.size > 0) {
      if (!this._isPaused) {
        this.queue.dequeue()();
      }
    } else {
      this._resolveEmpty();
      this._resolveEmpty = () => {};

      if (this._pendingCount === 0) {
        this._resolveIdle();
        this._resolveIdle = () => {};
      }
    }
  }
  /** Adds a sync or async task to the queue. Always returns a promise. */
  add(fn, opts) {
    return new Promise((resolve, reject) => {
      const run = () => {
        this._pendingCount++;

        try {
          Promise.resolve(fn()).then(
            val => {
              resolve(val);
              this._next();
            },
            err => {
              reject(err);
              this._next();
            },
          );
        } catch (err) {
          reject(err);
          this._next();
        }
      };

      if (!this._isPaused && this._pendingCount < this.concurrency) {
        run();
      } else {
        this.queue.enqueue(run, opts);
      }
    });
  }
  /** Same as `.add()`, but accepts an array of sync or async functions and
   * returns a promise that resolves when all functions are resolved. */
  addAll(fns, opts) {
    return Promise.all(fns.map(fn => this.add(fn, opts)));
  }
  /** Start (or resume) executing enqueued tasks within concurrency limit.
   * No need to call this if queue is not paused (via
   * `options.autoStart = false` or by `.pause()` method.) */
  start() {
    if (!this._isPaused) {
      return;
    }

    this._isPaused = false;
    while (this.queue.size > 0 && this._pendingCount < this.concurrency) {
      this.queue.dequeue()();
    }
  }
  /** Put queue execution on hold. */
  pause() {
    this._isPaused = true;
  }
  /** Clear the queue. */
  clear() {
    this.queue = new this._queueClass(); // eslint-disable-line new-cap
  }
  /** Returns a promise that settles when the queue becomes empty. Can be
   * called multiple times. Useful if you, for example, add additional items at
   * a later time. **NOTE: Use onIdle to detect when queue is finished!** */
  onEmpty() {
    // Instantly resolve if the queue is empty
    if (this.queue.size === 0) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      const existingResolve = this._resolveEmpty;
      this._resolveEmpty = () => {
        existingResolve();
        resolve();
      };
    });
  }
  /** Returns a promise that settles when the queue becomes empty, and all
   * promises have completed; `queue.size === 0 && queue.pending === 0`.
   * The difference with `.onEmpty` is that `.onIdle` guarantees that all work
   * from the queue has finished. `.onEmpty` merely signals that the queue is
   * empty, but it could mean that some promises haven't completed yet. */
  onIdle() {
    // Instantly resolve if none pending
    if (this._pendingCount === 0) {
      return Promise.resolve();
    }

    return new Promise(resolve => {
      const existingResolve = this._resolveIdle;
      this._resolveIdle = () => {
        existingResolve();
        resolve();
      };
    });
  }
  /** Size of the queue. */
  get size() {
    return this.queue.size;
  }
  /** Number of pending promises. */
  get pending() {
    return this._pendingCount;
  }
  /** Whether the queue is currently paused. */
  get isPaused() {
    return this._isPaused;
  }
}
export default PQueue;
