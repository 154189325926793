export const FileSelectors = {
  isFilePublishedOrPublishing(id, state) {
    const {
      files: { [id]: file },
    } = state;
    if (file && file.publishing_status) {
      return true;
    }
    return false;
  },
  isFileUploadCompleted(id, state) {
    const {
      files: { [id]: file },
    } = state;
    if (file && file.upload_completed) {
      return true;
    }
    return false;
  },
};
