import { UIActions } from "./actions";

const DEFAULT_NET_SPEED = parseInt(process.env.REACT_APP_DEFAULT_NET_SPEED, 10);

export const UIState = {
  name: "ui",
  persist: true,
  defaults: {
    mainTabIndex: 0,
    networkSpeed: DEFAULT_NET_SPEED, // 1: Slow, 2: Fast, 3: Ultra Fast
    verifyContentHashDisabled: false,
  },
  handlers: {
    [UIActions.NETWORK_SPEED_CHANGE]: (state, action) => {
      return {
        ...state,
        networkSpeed: action.payload,
      };
    },
    [UIActions.TAB_SELECT]: (state, action) => {
      return {
        ...state,
        mainTabIndex: action.payload,
      };
    },
    [UIActions.VERIFY_CONTENT_HASH_TOGGLE]: (state /*, action */) => {
      return {
        ...state,
        verifyContentHashDisabled: !state.verifyContentHashDisabled,
      };
    },
  },
};
