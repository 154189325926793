import APIClient from "./APIClient";
import { toast } from "react-toastify";
import { arrayToObjectById } from "../lib";

/** @param {string} id.
 * @param {string} action URL slug for the REST action. */
function fileActionURL(id, action) {
  return fileURL(id) + "/" + action;
}
/** @param {string} id. */
function fileURL(id) {
  return "/uploads/" + encodeURIComponent(id);
}

export const UploadsAPI = {
  /** @param {string} id
   * @param {CompletedUploadParams} params
   */
  async completedUpload(id, params) {
    const { data } = await APIClient.post(fileURL(id), params).catch(err => {
      toast.error("Oops Upload failed! Tech team has been notified.");
      throw err;
    });
    return data;
  },
  /** @param {CreateJoinParams} params */
  async createJoin(params) {
    const { data } = await APIClient.post("/uploads/join", params);
    return data;
  },
  /** @param {string} id.
   * @param {CreatePreviewParams} params */
  async createPreview(id, params) {
    const { data } = await APIClient.post(fileActionURL(id, "preview"), params);
    return data;
  },
  /** @param {string} id.
   * @param {CreateTrimParams} params */
  async createTrim(id, params) {
    return APIClient.post(fileActionURL(id, "trim"), params);
  },
  /** @param {CreateUploadParams} params
   * @returns {CreateUploadResult}
   */
  async createUpload(params) {
    const { data } = await APIClient.post("/uploads", params).catch(err => {
      if (err && err.response && err.response.status === 403) {
        return { data: { unauthorized: true } };
      } else {
        return Promise.reject(err);
      }
    });
    return data;
  },
  /** @param {CreateUploadParams} params
   * @returns {CreateUploadUrl}
   */ async createUploadUrl(params) {
    console.log("createUploadUrl", params);
    params.uploader_version = process.env.REACT_APP_VERSION;
    params.user_agent = window.navigator.userAgent;
    const { data } = await APIClient.post("/uploads/url-fetch", params).catch(
      err => {
        if (err && err.response && err.response.status === 403) {
          return { data: { unauthorized: true } };
        } else if (err.response) {
          return { data: err.response.data };
        } else {
          return { data: { message: "unknown error occurred" } };
        }
      },
    );
    return data;
  },
  /** @param {string} id. */
  async deleteUpload(id) {
    return APIClient.delete(fileURL(id));
  },
  /** Gets the list of files already uploaded to the server.
   * @param {{device_id:string}} user
   * @returns {GetUploadsResult}
   */
  async getUploads(user) {
    const user_device_id = user.device_id;
    const { data } = await APIClient.get(
      `/uploads?user_device_id=${encodeURIComponent(user_device_id)}`,
    );
    return arrayToObjectById(data);
  },
  /** @param {string} id */
  async getUpload(id) {
    const { data } = await APIClient.get(fileURL(id));
    return data;
  },
  /** @param {string} id */
  async getDownloadUrl(id) {
    const { data } = await APIClient.get(
      "uploads/" + id + "/get-download-link",
    );
    return data;
  },
  /** @param {string} id File Id.
   * @param {string} multipart_id Storage provider upload Id.
   * @param {number} part The part number.
   * @param {string} content_md5_hash The hash of this part
   * @returns {Promise<string>} The url.
   */
  async getUploadPartURL(id, multipart_id, part, content_md5_hash) {
    let url = fileActionURL(
      id,
      "upload-part" +
        `?multipart_id=${encodeURIComponent(multipart_id)}` +
        `&part=${part}`,
    );

    if (content_md5_hash) {
      url += `&content_md5_hash=${encodeURIComponent(content_md5_hash)}`;
    }

    const { data } = await APIClient.get(url).catch(err => {
      if (err && err.response && err.response.status === 403) {
        return { data: { upload_url: false } };
      } else {
        return Promise.reject(err);
      }
    });
    return data.upload_url;
  },
  /** Gets the list of upload users.
   * @returns {GetUsersResult}
   */
  async getUsers() {
    const { data } = await APIClient.get("/uploads/users");
    return arrayToObjectById(data);
  },
  /** Gets users that have uploaded files, with files listing.
   * @returns {GetUsersWithFilesResult}
   */
  async getUsersWithFiles() {
    const { data } = await APIClient.get("/uploads/users/files");
    return arrayToObjectById(data, item => {
      item.files = arrayToObjectById(item.files);
      return item;
    });
  },
  async publish(id, params) {
    return APIClient.post(fileActionURL(id, "publish"), params);
  },
  /** @param {string} id. */
  resetTrim(id) {
    return APIClient.delete(fileActionURL(id, "trim"));
  },
  /** @param {TransferParams} payload
   * @returns {object[]} Array of files if direction is FROM, else empty array.
   */
  async transfer(payload) {
    const { data } = await APIClient.post("/uploads/transfer", payload);
    return data;
  },
  async getPrefetchedStatus(fileId) {
    const data = await APIClient.get(fileActionURL(fileId, "prefetched")).catch(
      err => {
        if (err && err.response && err.response.status === 403) {
          return { data: { unauthorized: true } };
        } else {
          return Promise.reject(err);
        }
      },
    );
    return data;
  },
};

// #region Typedefs
/** @typedef {object} CompletedUploadParams
 * @property {number} upload_time In seconds.
 */
/** @typedef {object} CreateJoinParams
 * @property {string[]} ids
 * @property {string} name
 */
/** @typedef {object} CreateJoinResult
 * @property {string} id
 * @property {number} length
 * @property {number} bytes
 */
/** @typedef {object} CreatePreviewParams
 * @property {'begin'|'end'} part
 * @property {number} seconds
 * @property {string} zone
 */
/** @typedef {object} CreatePreviewResult
 * @property {string} url
 */
/** @typedef {object} CreateTrimParams
 * @property {'begin'|'end'} part
 * @property {number} seconds
 */
/** @typedef {object} CreateUploadParams
 * @property {string} name
 * @property {number} size
 * @property {string} mime_type
 */
/** @typedef {object} CreateUploadResult
 * @property {string} upload_url
 */
/** @typedef {object} CreateUploadUrl
 * @property {string} upload_url
 */
/** @typedef {object} GetUploadsFileData
 * @property {string} id Id of the file.
 * @property {string} name Name of the file.
 * @property {string} mime_type MIME type of the file.
 * @property {number} size Size in bytes.
 * @property {string} hash MD5 Hash of the file.
 * @property {number} created Time created on the server.
 */
/** @typedef {{[x:string]: GetUploadsFileData}} GetUploadsResult
 */
/** @typedef {object} TransferParams
 * @property {('from'|'to')} direction
 * @property {string[]} ids
 * @property {number|string} userId
 */
/** @typedef {object} UserData
 * @property {string} id Id of the user.
 * @property {string} name Full name of the user.
 * @property {string} email Email address of the user.
 */
/** @typedef {object} UserFileData
 * @property {number} created Epoch of created date from `Date.now()`.
 * @property {string} id Id of the file.
 * @property {string} mime_type MIME type of the file ('video/mp4', etc.)
 * @property {string} name Name of the file, including file extension.
 * @property {number} size Size in bytes
 */
/** @typedef {object} UserWithFilesData
 * @property {string} id Id of the user.
 * @property {string} email Email address of the user.
 * @property {{[x:string]: UserFileData}} files
 * @property {string} name Full name of the user.
 */
/** @typedef {{[x:string]: UserData}} GetUsersResult
 */
/** @typedef {{[x:string]: UserWithFilesData}} GetUsersWithFilesResult
 */
// #endregion
