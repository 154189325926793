/** @file Copied from https://github.com/rt2zz/redux-persist-crosstab/pull/17
 * via https://raw.githubusercontent.com/rt2zz/redux-persist-crosstab/178816cbabbfe2babfeb3a8ae1486ac0b9cc6984/index.js
 *
 * - Replaced Flow types with Typescript/JSDoc annotations.
 * - Using ES6 exports.
 * - Changed formatting.
 */
import { KEY_PREFIX, REHYDRATE } from "redux-persist";

/** Initializes cross-tab syncing of redux state stored in `localeStorage`.
 * @param {Persistor} store
 * @param {PersistConfig} persistConfig
 * @param {CrossTabConfig} [crossTabConfig]
 */
export function crossTabSync(store, persistConfig, crosstabConfig = {}) {
  const blacklist = crosstabConfig.blacklist || null;
  const whitelist = crosstabConfig.whitelist || null;
  const keyPrefix = crosstabConfig.keyPrefix || KEY_PREFIX;

  const { key } = persistConfig;

  window.addEventListener("storage", handleStorageEvent, false);

  function handleStorageEvent(e) {
    if (e.key && e.key.indexOf(keyPrefix) === 0) {
      if (e.oldValue === e.newValue) {
        return;
      }
      const statePartial = JSON.parse(e.newValue);
      const state = Object.keys(statePartial).reduce((state, reducerKey) => {
        if (whitelist && whitelist.indexOf(reducerKey) === -1) {
          return state;
        }
        if (blacklist && blacklist.indexOf(reducerKey) !== -1) {
          return state;
        }
        state[reducerKey] = JSON.parse(statePartial[reducerKey]);
        return state;
      }, {});
      store.dispatch({
        key,
        payload: state,
        type: REHYDRATE,
      });
    }
  }
}

// #region Typedefs

// See redux-persist types @ https://github.com/rt2zz/redux-persist/blob/master/src/types.js

/** @typedef {object} CrossTabConfig
 * @property {string[]} [blacklist] State keys to exclude.
 * @property {string} [keyPrefix] Key prefix (**NOTE: Will be removed in redux-persist v6**).
 * @property {string[]} [whitelist] State keys to include.
 */
/** @typedef {object} PersistConfig
 * @property {number} [version]
 * @property {object} storage
 * @property {string} key
 * @property {string} [keyPrefix]
 * @property {string[]} [blacklist]
 * @property {string[]} [whitelist]
 * @property {Object[]} [transforms]
 * @property {number} [throttle]
 * @property {function} [migrate]
 * @property {boolean|function} [stateReconciler]
 * @property {function} [getStoredState]
 * @property {boolean} [debug]
 * @property {boolean} [serialize]
 * @property {number} [timeout]
 */
/** @typedef {object} Persistor
 * @method {function} pause
 * @method {function} persist
 * @method {function} purge
 * @method {function} dispatch
 * @method {function} getState
 * @method {function} subscribe
 */
// #endregion
