import axios from "axios";
import { captureException, withScope } from "@sentry/browser";
import {
  getContentHash,
  handleFileProgress,
  replaceHostWithFallbackURL,
  shouldTestUploadError,
} from "./shared";

export class FileUploader {
  /** Creates a new `FileUploader`.
   * @param {FileUploaderParams} params
   */
  constructor(params) {
    /** The file being uploaded. */
    this.file = params.file;
    const {
      fallbackAfter,
      fallbackURL,
      onUploadError,
      onUploadProgress,
      retryWait,
      timeout = 0,
      timeStarted,
    } = params;
    /** @type {number} */
    this.fallbackAfter = fallbackAfter;
    /** @type {string} */
    this.fallbackURL = fallbackURL;
    if (typeof onUploadError === "function") {
      this.onUploadError = onUploadError;
    }
    if (typeof onUploadProgress === "function") {
      this.onUploadProgress = onUploadProgress;
    }
    this.retryWaitCount = 0;
    /** @type {number} */
    this.retryWaitInit = retryWait.init;
    /** @type {number} */
    this.retryWaitFactor = retryWait.factor;
    /** @type {number} */
    this.retryWaitMax = retryWait.max;
    /** Milliseconds timeout for Axios.
     * @type {number} */
    this.timeout = timeout;
    /** @type {Date} */
    this.timeStarted = timeStarted;
    /** Headers to attach during upload. */
    this.upload_headers = params.upload_headers;
    /** The URL to upload to. */
    this.url = params.upload_url;
    /** True to disable MD5 hashing. */
    this.verifyContentHashDisabled = params.verifyContentHashDisabled;
  }
  /** Cancels the current upload. */
  cancel = message => {
    const { cancelAxios } = this;
    this.cancelled = true;
    if (this.retryWaitTimer) {
      clearTimeout(this.retryWaitTimer);
      if (this.retryWaitStop) {
        this.retryWaitStop();
      }
    }
    if (cancelAxios) {
      cancelAxios(message);
    }
  };

  onRetryWait = () => {
    this.retryWaitCount += 1;
    if (this.retryWaitCount > 1) {
      this.retryWaitTimeout = Math.min(
        this.retryWaitTimeout * this.retryWaitFactor,
        this.retryWaitMax,
      );
    } else {
      this.retryWaitTimeout = this.retryWaitInit;
    }
    console.log(`Waiting ${this.retryWaitTimeout}...`);
    this.startRetryCountdown(this.retryWaitTimeout);
    return new Promise(resolve => {
      this.retryWaitStop = () => {
        console.log("Done waiting...");
        delete this.retryWaitStop;
        delete this.retryWaitTimer;
        resolve();
      };
      this.retryWaitTimer = setTimeout(
        this.retryWaitStop,
        this.retryWaitTimeout,
      );
    });
  };

  retryCountdown = () => {
    const remaining = this.retryWaitRemaining / 1000;
    this.onUploadProgress(undefined, `Retrying in ${remaining}...`);
    this.retryWaitRemaining = this.retryWaitRemaining - 1000;
    if (this.retryWaitRemaining > 0) {
      setTimeout(this.retryCountdown, 1000);
    }
  };

  startRetryCountdown = remaining => {
    if (
      !this.retryWaitRemaining ||
      this.retryWaitRemaining <= 0 ||
      remaining < this.retryWaitCount
    ) {
      this.retryWaitRemaining = remaining;
      this.retryCountdown();
    }
  };

  /** Default error handler.
   * @param {Error & {level:string, type:string}} error
   */
  onUploadError = function(error) {
    console.error(error);
  };
  /** Upload progress event handler.
   * @param {ProgressEvent} e Native `ProgressEvent`
   * @param {number|string} progress Calculated progress percentage
   * (`0.00 - 100.00`) or a progress message.
   */
  onUploadProgress = function(e, progress) {};
  /** Starts the upload.
   * @returns {Promise<boolean>} True if successful.
   */
  start = async () => {
    let {
      fallbackAfter,
      fallbackURL,
      file,
      onUploadProgress,
      timeout,
      url,
      upload_headers,
      verifyContentHashDisabled,
    } = this;
    const contentMD5 = verifyContentHashDisabled
      ? null
      : await getContentHash(file);
    const uploadConfig = {
      cancelToken: new axios.CancelToken(cancel => {
        this.cancelAxios = cancel;
      }),
      headers: {
        "Content-Type": file.type,
        ...upload_headers,
      },
      onUploadProgress: handleFileProgress(onUploadProgress),
      timeout,
    };
    if (!verifyContentHashDisabled) {
      uploadConfig.headers["Content-MD5"] = shouldTestUploadError
        ? "INCORRECT-MD5"
        : contentMD5;
    }
    let usingFallback = shouldTestUploadError;
    const onError = err => {
      if (axios.isCancel(err)) {
        this.cancelled = true;
        console.log(`Cancelled upload of "${file.name}"`);
        return undefined;
      }
      console.warn(
        `${err.message} - { code: ${err.code}, ` +
          `response: ${!!err.response}, ` +
          `status: ${err.response ? err.response.status : ""} }`,
      );
      withScope(scope => {
        scope.setTag("axerr-code", err.code);
        scope.setTag("axerr-response", !!err.response);
        scope.setTag(
          "axerr-response-status",
          err.response ? err.response.status : "",
        );
        scope.setTag("axerr-status", err.status);
        captureException(err);
      });
      // Detect network error and retry with fallbackURL
      // - Based on our logging, err.response is missing when there is a true
      // network error.
      // - https://github.com/axios/axios/issues/383#issuecomment-316501886
      if (!usingFallback && Date.now() - this.timeStarted >= fallbackAfter) {
        // We don't need this warning because we're just going to keep trying...
        // if (!this.warned) {
        //   this.warned = true;
        //   this.onUploadError({ level: 'warning', type: 'fallback' });
        // }
        console.log("Using fallback url...");
        usingFallback = true;
        const tryURL = replaceHostWithFallbackURL(url, fallbackURL);
        return axios.put(tryURL, file, uploadConfig).catch(onError);
      }
      // Retry continually:
      return this.onRetryWait().then(() => {
        if (this.cancelled) {
          return;
        }
        return axios.put(url, file, uploadConfig).catch(onError);
      });
      // To stop trying and fail:
      // delete this.cancelAxios;
      // this.failed = true;
      // this.onUploadError(err);
    };
    const response = await axios.put(url, file, uploadConfig).catch(onError);
    delete this.cancelAxios;
    return this.cancelled || this.failed ? false : !!response;
  };
}
export default FileUploader;

// #region Typedefs
/** @typedef {object} FileUploaderParams
 * @property {File} file
 * @property {string} upload_url
 * @property {object} upload_headers
 * @property {(e:ProgressEvent,progress:number)=> void} [onUploadProgress] Upload
 * progress event handler.
 */
// #endregion
