import React from "react";
import ReactPlayer from "react-player";

const MIN_DURATION = process.env.REACT_APP_UPLOAD_MEDIA_MIN_DURATION || "3";

export class PreviewPlayer extends React.PureComponent {
  state = {
    playing: false,
    playingToMarker: false,
    playedSeconds: 0,
  };

  /** @param {PlayProgressEvent} e */
  onPlayProgress = e => {
    this.setState((state, props) => {
      const { marker = 0 } = props;
      const update = {
        playedSeconds: e.playedSeconds,
      };
      if (state.playingToMarker && e.playedSeconds >= marker) {
        update.playing = false;
        update.playingToMarker = false;
      }
      return update;
    });
  };

  /** @param {React.MouseEvent<HTMLButtonElement>} e */
  onMarkTime = e => {
    const { onMarkTime, part } = this.props;
    const { playedSeconds } = this.state;
    onMarkTime({
      part,
      markedSeconds: playedSeconds,
    });
  };

  onMarkUpdate = e => {
    const change = parseFloat(e.target.attributes["data-change"].value);
    const { marker, part, onMarkTime } = this.props;
    const current = marker || this.player.getCurrentTime() || 0;
    const time = current + change;
    onMarkTime({
      part,
      markedSeconds: time,
    });
    this.playerSeek(time);
  };

  onPause = () => {
    this.setState({
      playing: false,
    });
  };

  onPlay = () => {
    this.setState({
      playing: true,
    });
  };

  onPlayFromMarker = () => {
    const time = this.props.marker || 0;
    this.playerSeek(time);
    this.setState({
      playing: true,
    });
  };

  onPlayToMarker = () => {
    this.playerSeek(0);
    this.setState({
      playing: true,
      playingToMarker: true,
    });
  };

  onSeekBegin = () => {
    this.playerSeek(0);
  };

  onSeekEnd = () => {
    const time =
      this.player.getDuration() || this.player.getSecondsLoaded() || 0;
    this.playerSeek(time);
  };

  playerSeek(time) {
    // NOTE: this.player.seekTo() with fractions between 0.0 and 1.0 won't work
    // because of a bad design decision in react-player. (A time of 0.5 will
    // seek to half-way through the video...)
    // See https://github.com/CookPete/react-player/issues/75
    // So, we're using `this.player.getInternalPlayer().currentTime = `
    // as a workaround instead of `this.player.seekTo(time);`
    this.player.getInternalPlayer().currentTime = time;
    // WARNING: `getInternalPlayer().currentTime` only works when the internal
    // player is a `<video>` element. If playing directly from Vimeo,
    // replace `currentTime` with the appropriate method call or property.
  }

  refPlayer = player => {
    this.player = player;
  };

  render() {
    const {
      props: { marker = 0, markersInvalid, part, url },
      state: { playing },
    } = this;
    return (
      <div className="preview_block">
        <div className="cns_header">
          <span className="cns_header_title">
            <i className="material-icons">skip_previous</i>
            Trim {part === "end" ? "OUT" : "IN"} Point:
            <input className="cns_input" value={marker} readOnly />
          </span>
        </div>
        <ReactPlayer
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
                width: "448",
                style: {},
              },
            },
          }}
          controls={true}
          playing={playing}
          url={url}
          onPause={this.onPause}
          onPlay={this.onPlay}
          onProgress={this.onPlayProgress}
          progressInterval={100}
          ref={this.refPlayer}
          width={null}
          height={null}
        />
        <div className="cns_block">
          <div className="cns_controls">
            {markersInvalid ? (
              <span style={{ color: "red" }}>
                Invalid marker. The minimum media length is
                <strong> {MIN_DURATION} seconds</strong>.
              </span>
            ) : (
              <span>&nbsp;</span>
            )}
            <div className="cns_tools_block">
              {part === "begin" ? (
                <div
                  className="cns_btn cns_btn_main"
                  onClick={this.onPlayFromMarker}
                >
                  Play from Marker
                </div>
              ) : (
                <div
                  className="cns_btn cns_btn_main"
                  onClick={this.onPlayToMarker}
                >
                  Play to Marker
                </div>
              )}
              <div
                className="cns_btn"
                onClick={this.onMarkUpdate}
                data-change="-1.0"
              >
                ◄ 1.0
              </div>
              <div
                className="cns_btn"
                onClick={this.onMarkUpdate}
                data-change="-0.1"
              >
                ◄ 0.1
              </div>
              <div className="cns_btn cns_btn_main" onClick={this.onMarkTime}>
                Mark
              </div>
              <div
                className="cns_btn"
                onClick={this.onMarkUpdate}
                data-change="0.1"
              >
                0.1 ►
              </div>
              <div
                className="cns_btn"
                onClick={this.onMarkUpdate}
                data-change="1.0"
              >
                1.0 ►
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/** @typedef {object} PlayProgressEvent
 * @property {number} loaded Percentage loaded as a fraction.
 * @property {number} loadedSeconds Seconds loaded.
 * @property {number} played Percentage played as a fraction.
 * @property {number} playedSeconds Seconds played.
 */
