import { PublishingActions } from "./actions";

export const PublishingState = {
  name: "publishing",
  persist: false,
  defaults: {},
  handlers: {
    [PublishingActions.FILE_SUBMIT](state, action) {
      const { fileId, lectureId } = action.payload;
      return {
        ...state,
        fileId,
        lectureId,
      };
    },
  },
};
