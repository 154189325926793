import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { FileActions, TrimmerActions } from "../state";
import { Trimmer as View } from "./Trimmer";

export const Trimmer = connect(
  function mapState(state) {
    const {
      files,
      trimmer: {
        fileToTrim,
        loading,
        markersInvalid,
        preview_begin,
        preview_begin_error,
        preview_end,
        preview_end_error,
      },
    } = state;
    return {
      files,
      fileToTrim,
      loading,
      markersInvalid,
      preview_begin,
      preview_begin_error,
      preview_end,
      preview_end_error,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: {
        ...bindActionCreators(FileActions, dispatch),
        ...bindActionCreators(TrimmerActions, dispatch),
      },
    };
  },
)(View);
