export const UIActions = {
  NETWORK_SPEED_CHANGE: "NETWORK_SPEED_CHANGE",
  TAB_SELECT: "TAB_SELECT",
  VERIFY_CONTENT_HASH_TOGGLE: "VERIFY_CONTENT_HASH_TOGGLE",

  changeNetworkSpeed(value) {
    if (value < 1) {
      throw new Error("networkSpeed must be greater than 1.");
    }
    return { type: UIActions.NETWORK_SPEED_CHANGE, payload: value };
  },

  selectTab(index) {
    return { type: UIActions.TAB_SELECT, payload: index };
  },

  toggleVerifyContentHash() {
    return { type: UIActions.VERIFY_CONTENT_HASH_TOGGLE };
  },
};
