import { APIClient, authTokenMissingMessage } from "./APIClient";
import { getUUIDv4 } from "../lib/utilities";

const { NODE_ENV } = process.env;

const __DEV__ = NODE_ENV === "development";

async function mockUserSignin() {
  const { REACT_APP_MOCK_USER, REACT_APP_MOCK_PASS } = process.env;
  if (!REACT_APP_MOCK_USER || !REACT_APP_MOCK_PASS) {
    return Promise.resolve();
  }
  await APIClient.post("/login", {
    email: REACT_APP_MOCK_USER,
    password: REACT_APP_MOCK_PASS,
  });
}

export const AuthAPI = {
  authTokenMissingMessage,
  /**
   * Gets the signed-in user information from the server.
   * @param {boolean} [retry]
   */
  async getSignedInUser(retry) {
    // Try to get the signed-in users data once. If that fails while in
    // development, do a mock-signin then try again. Otherwise, during
    // production - simply return undefined data.
    let { data } = await APIClient.get("/users/mine").catch(async err => {
      if (!retry && __DEV__) {
        await mockUserSignin();
      }
      return { err, data: undefined };
    });
    if (data) {
      // Get device UUID.
      //
      // NOTE: The react-admin frontend generates "device_uuid" when loading.
      // When the uploader is served from the same domain and port, it should
      // be able to access that same key in localStorage.
      //
      // Developers can copy device_uuid from react-admin localStorage, set a
      // MOCK uuid or simply use the one returned from "users/mine"...
      data.device_id =
        localStorage.getItem("device_uuid") ||
        localStorage.getItem("user_uuid"); // "user_uuid" is legacy.

      if (!data.device_id) {
        console.log("missing device_uuid");
        const uuidv4 = getUUIDv4();
        console.log("setting UUID", uuidv4);
        localStorage.setItem("device_uuid", uuidv4);
        data.device_id = uuidv4;
      }
    } else if (!retry && __DEV__) {
      return AuthAPI.getSignedInUser(true);
    }
    return data;
  },
};
