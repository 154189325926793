import React from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { sortObjectKeysByNameAndId } from "../lib";
import {
  FileList,
  TransferFromUserModal,
  TransferToUserModal,
} from "../components";

const { REACT_APP_VERSION } = process.env;

export class Uploader extends React.PureComponent {
  state = {
    displayTransferFromUserModal: false,
    displayTransferToUserModal: false,
    // skipIntro: false,
  };

  changeNetworkSpeed = e => {
    const networkSpeed = parseInt(e.target.attributes["data-speed"].value, 10);
    const {
      actions: { changeNetworkSpeed },
    } = this.props;
    changeNetworkSpeed(networkSpeed);
  };

  hideTransferFromUserModal = () => {
    this.setState({
      displayTransferFromUserModal: false,
    });
  };

  hideTransferToUserModal = () => {
    this.setState({
      displayTransferToUserModal: false,
    });
  };

  /** @param {File[]} files */
  onDropFile = files => {
    const {
      props: {
        actions: { upload },
      },
      resumingUploadId,
    } = this;
    this.resumingUploadId = undefined;
    const { upload_zone } = this.props;
    if (resumingUploadId && files.length > 0) {
      upload(upload_zone, files[0], resumingUploadId);
    } else {
      files.forEach(file => upload(upload_zone, file));
    }
  };

  refDropzone = target => {
    this.dropzone = target;
  };

  resumeUpload = fileId => {
    if (!this.dropzone) {
      throw new Error("File dropzone not found.");
    }
    this.resumingUploadId = fileId;
    this.dropzone.open();
  };

  showTransferFromUserModal = () => {
    this.setState({
      displayTransferFromUserModal: true,
    });
  };

  showTransferToUserModal = () => {
    const { selectedUpload } = this.props;
    if (!selectedUpload) {
      window.alert("Please select a file.");
      return;
    }
    this.setState({
      displayTransferToUserModal: true,
    });
  };

  onChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  // handlePublish = () => {
  //   const { skipIntro } = this.state;
  //   this.props.actions.publishLecture({ skipIntro });
  // };

  render() {
    const {
      props: {
        actions: {
          cancel,
          cancelDeleteFile,
          // createLecture,
          deleteFile,
          loadAllFiles,
          selectUpload,
          showDeleteFile,
          toggleVerifyContentHash,
        },
        // editingLectureId,
        files,
        networkSpeed,
        selectedUpload,
        userIsAdmin,
        verifyContentHashDisabled,
      },
      resumeUpload,
      state: {
        displayTransferFromUserModal,
        displayTransferToUserModal,
        // skipIntro,
      },
    } = this;
    const fileIds = sortObjectKeysByNameAndId(files);
    return (
      <section>
        <aside>
          <FileList
            fileIds={fileIds}
            files={files}
            heading={
              <React.Fragment>
                Upload / Select a file to Process
                <br />
                ...
              </React.Fragment>
            }
            onCancelDelete={cancelDeleteFile}
            onCancelUpload={cancel}
            onDelete={deleteFile}
            onRefresh={loadAllFiles}
            onResumeUpload={resumeUpload}
            onSelect={selectUpload}
            onShowDelete={showDeleteFile}
            selectedId={selectedUpload}
          />
        </aside>
        {userIsAdmin && (
          <div className="admin_toolbar">
            <button
              onClick={this.showTransferToUserModal}
              style={{ width: 160 }}
            >
              Transfer TO user...
            </button>
            <button
              onClick={this.showTransferFromUserModal}
              style={{ float: "right", width: 160 }}
            >
              Transfer FROM user...
            </button>
          </div>
        )}
        <Dropzone
          className="dropzone"
          onDrop={this.onDropFile}
          ref={this.refDropzone}
        >
          <div className="dropzone-button">
            <div>Select files to Upload</div>
          </div>
          <div className="dropzone-text">or drag &amp; drop them here</div>
        </Dropzone>
        <div id="welcome">
          <span style={{ float: "left", marginTop: 4 }}>
            v{REACT_APP_VERSION}
          </span>
          <span id="ispeed">
            My upload connection is{" "}
            <span
              className={classNames("ispeed", { active: networkSpeed === 1 })}
              data-speed={1}
              onClick={this.changeNetworkSpeed}
            >
              Slow
            </span>
            <span
              className={classNames("ispeed", { active: networkSpeed === 2 })}
              data-speed={2}
              onClick={this.changeNetworkSpeed}
            >
              Fast
            </span>
            <span
              className={classNames("ispeed", { active: networkSpeed === 3 })}
              data-speed={3}
              onClick={this.changeNetworkSpeed}
            >
              Ultra Fast
            </span>
          </span>
          <div className="row-2">
            <label>
              Verify contents while uploading.
              <input
                type="checkbox"
                checked={!verifyContentHashDisabled}
                onChange={toggleVerifyContentHash}
              />
            </label>
          </div>
          {/* ADMIN SITE CODE: <div className="row-3">
            {editingLectureId > -1 && (
              <React.Fragment>
                <label style={{ marginLeft: 35, marginRight: 75 }}>
                  Skip intro
                  <input
                    style={{ marginLeft: 10 }}
                    type="checkbox"
                    checked={skipIntro}
                    onChange={this.onChange}
                    name="skipIntro"
                  />
                </label>
                <button className="button-mui" onClick={this.handlePublish}>
                  Publish Lecture
                </button>
              </React.Fragment>
            )} */}
          {/* ORIGINAL SITE CODE: <button
              className="button-mui"
              style={{ float: "right" }}
              onClick={createLecture}
            >
              Create Lecture
            </button> */}
          {/* </div> */}
        </div>
        {userIsAdmin && (
          <TransferToUserModal
            isOpen={displayTransferToUserModal}
            onRequestClose={this.hideTransferToUserModal}
            file={files[selectedUpload]}
          />
        )}
        {userIsAdmin && (
          <TransferFromUserModal
            isOpen={displayTransferFromUserModal}
            onRequestClose={this.hideTransferFromUserModal}
          />
        )}
      </section>
    );
  }
}
