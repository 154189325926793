import { captureException } from "@sentry/browser";
import { toast } from "react-toastify";
import {
  FileUploader,
  MultipartFileUploader,
  PQueue,
  confirmWindowUnload,
} from "../../lib";
import { ParentWindowAPI, UploadsAPI } from "../../api";
import { getContentHash } from "../../lib/file-uploader/shared";
import { FileActions } from "../files/actions";
import { FileSelectors } from "../files/selectors";

// #region ENV
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const UPLOADS_FALLBACK = process.env.REACT_APP_UPLOADS_FALLBACK;
const UPLOADS_FALLBACK_AFTER =
  parseFloat(process.env.REACT_APP_UPLOADS_FALLBACK_AFTER) * 1000;
const UPLOADS_RETRYWAIT_INIT =
  parseFloat(process.env.REACT_APP_UPLOADS_RETRYWAIT_INIT) * 1000;
const UPLOADS_RETRYWAIT_FACTOR = parseFloat(
  process.env.REACT_APP_UPLOADS_RETRYWAIT_FACTOR,
);
const UPLOADS_RETRYWAIT_MAX =
  parseFloat(process.env.REACT_APP_UPLOADS_RETRYWAIT_MAX) * 1000;
const UPLOADS_TIMEOUT =
  parseInt(process.env.REACT_APP_UPLOADS_TIMEOUT, 10) * 1000;
const MULTIPART_UPLOADS_MINSIZE = parseInt(
  process.env.REACT_APP_MULTIPART_UPLOADS_MINSIZE,
  10,
);
const MULTIPART_UPLOADS_PARTSIZE = parseInt(
  process.env.REACT_APP_MULTIPART_UPLOADS_PARTSIZE,
  10,
);
// #endregion

/** Message to show if the user attempts to navigate while uploading.
 * This particular message is a copy of the message shown in the original
 * implementation.
 */
const MSG_CONFIRM_UNLOAD = "Changes you made may not be saved.";
/** Function to call to cancel the window `beforeunload` handler.
 * @type {function}
 */
let cancelConfirmWindowUnload;

const activeUploadsById = {};

const fileUploadQueue = new PQueue({
  concurrency: 1, // Number of simultaneous uploads...
});

function getActiveUploadsCount() {
  return Object.keys(activeUploadsById).length;
}

function onUploadError(error) {
  if (error.level === "warning") {
    const message =
      error.type === "fallback"
        ? "Attempting fallback method."
        : error.toString();
    toast.warn(message, {
      type: "warning",
    });
  } else {
    captureException(error);
    toast.error("Oops Upload failed! Tech team has been notified.", {
      // autoClose: false, // If you want to leave it open for testing...
      autoClose: 3000,
      position: "top-left",
      hideProgressBar: true,
      type: "error",
      pauseOnFocusLoss: false,
      pauseOnHover: false,
    });
  }
}

function onUploadStart() {
  if (!cancelConfirmWindowUnload) {
    cancelConfirmWindowUnload = confirmWindowUnload(MSG_CONFIRM_UNLOAD);
  }
}

function onUploadActive() {
  ParentWindowAPI.activeUploads(getActiveUploadsCount());
}

function onUploadStop() {
  const activeUploadsCount = getActiveUploadsCount();
  ParentWindowAPI.activeUploads(activeUploadsCount);
  if (cancelConfirmWindowUnload) {
    // If there are ANY active uploads, don't cancel the window unload message.
    if (activeUploadsCount > 0) {
      return;
    }
    cancelConfirmWindowUnload();
    cancelConfirmWindowUnload = undefined;
  }
}

export const UploaderActions = {
  UPLOAD_CANCEL: "UPLOAD_CANCEL",
  UPLOAD_COMPLETE: "UPLOAD_COMPLETE",
  UPLOAD_FAILED: "UPLOAD_FAILED",
  UPLOAD_PROGRESS: "UPLOAD_PROGRESS",
  UPLOAD_RESUME: "UPLOAD_RESUME",
  UPLOAD_SELECT: "UPLOAD_SELECT",
  UPLOAD_USERS_GET: "UPLOAD_USERS_GET",
  UPLOAD_USERS_WITH_FILES_GET: "UPLOAD_USERS_WITH_FILES_GET",
  UPLOAD_TRANSFER_FROM_USER: "UPLOAD_TRANSFER_FROM_USER",
  UPLOAD_TRANSFER_TO_USER: "UPLOAD_TRANSFER_TO_USER",
  CORRUPT: "CORRUPT",
  PREFETCHING: "PREFETCHING",
  PREFETCHED: "PREFETCHED",
  PREFETCH_DOWNLOAD_PROGRESS: "PREFETCH_DOWNLOAD_PROGRESS",

  /** @param {File} file
   * @param {string} resumingUploadId
   */
  upload(upload_zone = "sfo2", file, resumingUploadId) {
    const { name, size, type: mime_type } = file;
    const resuming = !!resumingUploadId;
    return async function addingFile(dispatch, getState) {
      const state = getState();
      const {
        files,
        ui: { networkSpeed, verifyContentHashDisabled },
        user: { device_id },
      } = state;
      let multipart = size >= MULTIPART_UPLOADS_MINSIZE;
      /** @type {import('../../api').CreateUploadResult} */
      let fileInfo;
      if (resuming) {
        fileInfo = files[resumingUploadId];
        if (!fileInfo) {
          // Avoid "Cannot read property 'size' of undefined"...
          // Looks like this happened after a delete... See:
          // https://sentry.io/torah-anytime/uploader/issues/855509021/
          console.warn("Attempted resume upload for file not found in state.");
          return;
        }
        if (fileInfo.size !== size || fileInfo.name !== name) {
          console.warn("File name or size do not match.");
          window.alert("File name or size do not match.");
          return;
        }
        // Re-evaluate multipart in case .env settings changed...
        multipart = !!fileInfo.multipart_id;
        onUploadStart();
        dispatch({
          type: UploaderActions.UPLOAD_RESUME,
          payload: resumingUploadId,
        });
      } else {
        if (name in Object.keys(files).map(id => files[id].name)) {
          return;
        }
        onUploadStart();

        /**
         * MD5 hash only if upload is not multipart.
         *
         * Multipart will be set to null as it will be
         * generated when creating the part upload url
         */
        const contentMD5Hash =
          verifyContentHashDisabled || multipart
            ? null
            : await getContentHash(file);

        fileInfo = await UploadsAPI.createUpload({
          name,
          size,
          mime_type,
          multipart,
          multipart_sizes: MULTIPART_UPLOADS_PARTSIZE,
          uploader_version: process.env.REACT_APP_VERSION,
          user_agent: window.navigator.userAgent,
          user_device_id: device_id,
          upload_zone,
          // We only send the MD5 hash if needed
          ...(contentMD5Hash
            ? {
                content_md5_hash: contentMD5Hash,
              }
            : {}),
        });
      }
      const { id, unauthorized, file_zone } = fileInfo;
      if (unauthorized) {
        onUploadStop();
        window.alert("You are no longer authorized for uploads.");
        return;
      }
      const timeStarted = new Date();
      if (!resuming) {
        dispatch(FileActions.addFile(fileInfo));
      }
      function onUploadProgress(e, upload_progress) {
        if (!activeUploadsById[id]) {
          // NOTE: Cancelled.
          return;
        }
        dispatch({
          type: UploaderActions.UPLOAD_PROGRESS,
          payload: { id, upload_progress },
        });
      }
      function onGetUploadPartURL(multipart_id, part, content_md5_hash) {
        return UploadsAPI.getUploadPartURL(
          id,
          multipart_id,
          part,
          content_md5_hash,
        );
      }
      const uploaderParams = {
        fallbackAfter: UPLOADS_FALLBACK_AFTER,
        fallbackURL: `${REACT_APP_API_URL}/origin-bucket-${file_zone}/`,
        file,
        networkSpeed,
        onGetUploadPartURL,
        onUploadError,
        onUploadProgress,
        partSize: MULTIPART_UPLOADS_PARTSIZE,
        resuming,
        retryWait: {
          init: UPLOADS_RETRYWAIT_INIT,
          factor: UPLOADS_RETRYWAIT_FACTOR,
          max: UPLOADS_RETRYWAIT_MAX,
        },
        timeout: UPLOADS_TIMEOUT,
        timeStarted,
        verifyContentHashDisabled,
        file_zone,
        ...fileInfo, // multipart_id, upload_url, upload_headers, ...
      };
      const uploader = multipart
        ? new MultipartFileUploader(uploaderParams)
        : new FileUploader(uploaderParams);
      activeUploadsById[id] = uploader;
      onUploadActive();
      fileUploadQueue.concurrency = networkSpeed;
      const completed = await fileUploadQueue.add(uploader.start);
      // NOTE: Execution pauses here while the file is uploaded.
      delete activeUploadsById[id];
      if (!completed) {
        if (uploader.failed) {
          onUploadStop();
          dispatch({ type: UploaderActions.UPLOAD_FAILED, payload: id });
          await UploadsAPI.deleteUpload(id);
        } // else { The upload was simply cancelled, cleanup already happened... }
        return;
      }
      const completedParams = {
        upload_time: (new Date() - timeStarted) / 1000, // in seconds.
        upload_zone: file_zone,
      };
      if (multipart) {
        completedParams.multipart_id = uploader.multipart_id;
      }
      dispatch({
        type: UploaderActions.UPLOAD_PROGRESS,
        payload: { completing: true, id, upload_progress: "97.5%" },
      });
      const update = await UploadsAPI.completedUpload(id, completedParams);
      dispatch({
        type: UploaderActions.UPLOAD_PROGRESS,
        payload: { completing: true, id, upload_progress: "100%" },
      });
      onUploadStop();
      await prefetched(id, name, update, dispatch, getState);
    };
  },
  uploadUrl(params) {
    return function(dispatch, getState) {
      return UploadsAPI.createUploadUrl(params);
    };
  },
  cancel(id) {
    return async function cancellingUpload(dispatch, getState) {
      const uploader = activeUploadsById[id];
      if (uploader) {
        delete activeUploadsById[id];
        if (typeof uploader.cancel === "function") {
          uploader.cancel();
        }
      }
      onUploadStop();
      await UploadsAPI.deleteUpload(id);
      dispatch({ type: UploaderActions.UPLOAD_CANCEL, payload: id });
    };
  },

  selectUpload(id) {
    return (dispatch, getState) => {
      const state = getState();
      if (FileSelectors.isFilePublishedOrPublishing(id, state)) {
        return;
      }
      if (!FileSelectors.isFileUploadCompleted(id, state)) {
        return;
      }
      dispatch({ type: UploaderActions.UPLOAD_SELECT, payload: id });
      // NOTE: UPLOAD_SELECT toggles the selection, so we're calling getState()
      // again after the dispatch to get latest state.
      const {
        uploader: { selectedUpload },
        files: { [selectedUpload]: file },
      } = getState();
      ParentWindowAPI.selectedUpload(file);
    };
  },

  getUsers() {
    return async function gettingUsers(dispatch, getState) {
      dispatch({ type: UploaderActions.UPLOAD_USERS_GET, loading: true });
      const result = await UploadsAPI.getUsers();
      dispatch({
        type: UploaderActions.UPLOAD_USERS_GET,
        loading: false,
        payload: result,
      });
    };
  },

  getUsersWithFiles() {
    return async function gettingUsersWithFiles(dispatch, getState) {
      dispatch({
        type: UploaderActions.UPLOAD_USERS_WITH_FILES_GET,
        loading: true,
      });
      const result = await UploadsAPI.getUsersWithFiles();
      dispatch({
        type: UploaderActions.UPLOAD_USERS_WITH_FILES_GET,
        loading: false,
        payload: result,
      });
    };
  },

  /** Transfer a file FROM a user.
   * @param {string} fileId
   * @param {number|string} userId
   */
  transferFromUser(fileId, userId) {
    return async function transferringUpload(dispatch, getState) {
      dispatch({
        type: UploaderActions.UPLOAD_TRANSFER_FROM_USER,
        fileId,
        loading: true,
      });
      const files = await UploadsAPI.transfer({
        direction: "from",
        ids: [fileId],
        userId,
      });
      if (!files || files.length < 1) {
        throw new Error("Unexpected transfer result.");
      }
      dispatch({
        type: UploaderActions.UPLOAD_TRANSFER_FROM_USER,
        fileId,
        loading: false,
        success: true,
      });
      dispatch({
        type: FileActions.FILE_LOAD,
        payload: files[0],
      });
    };
  },

  /** Transfer a file TO a user.
   * @param {string} fileId
   * @param {number|string} userId
   */
  transferToUser(fileId, userId) {
    return async function transferringUpload(dispatch, getState) {
      dispatch({
        type: UploaderActions.UPLOAD_TRANSFER_TO_USER,
        fileId,
        loading: true,
      });
      await UploadsAPI.transfer({
        direction: "to",
        ids: [fileId],
        userId,
      });
      dispatch({
        type: UploaderActions.UPLOAD_TRANSFER_TO_USER,
        fileId,
        loading: false,
        success: true,
      });
      dispatch({ type: FileActions.FILE_DELETE, payload: fileId });
    };
  },
};
async function prefetched(id, name, update, dispatch, getState) {
  const state = getState();
  const { files } = state;
  const fileInState = files[id];
  if (!fileInState) {
    return;
  }
  dispatch({
    type: UploaderActions.PREFETCHING,
    payload: { id },
  });
  const res = await UploadsAPI.getPrefetchedStatus(id);
  const { exists, isStillDownloading, downloadProgress } = res.data;
  console.log({ downloadProgress });
  dispatch({
    type: UploaderActions.PREFETCH_DOWNLOAD_PROGRESS,
    payload: { id, downloadProgress },
  });
  if (exists && !isStillDownloading) {
    const response = await UploadsAPI.getUpload(id);
    const { is_corrupt } = response;
    if (is_corrupt) {
      dispatch({
        type: UploaderActions.CORRUPT,
        payload: { id, response },
      });
      return;
    }
    dispatch({
      type: UploaderActions.PREFETCHED,
      payload: { id },
    });
    dispatch({
      type: UploaderActions.UPLOAD_COMPLETE,
      payload: {
        id,
        name,
        update,
      },
    });
  } else if (exists) {
    return setTimeout(
      async () => await prefetched(id, name, update, dispatch, getState),
      5000,
    );
  } else {
    // Handle for when file !exists
    toast.error("Oops! we could not find your file! Please refresh!");
  }
}
