import { UploadsAPI } from "../../api";

export const FileActions = {
  FILE_ADD: "FILE_ADD",
  FILE_DELETE: "FILE_DELETE",
  FILE_LOAD: "FILE_LOAD",
  FILES_LOAD_ALL: "FILES_LOAD_ALL",
  FILE_SHOW_DELETE: "FILE_SHOW_DELETE",
  FILE_CANCEL_DELETE: "FILE_CANCEL_DELETE",

  /** @param {object} file */
  addFile(file) {
    return { type: FileActions.FILE_ADD, payload: file };
  },
  cancelDeleteFile(id) {
    return { type: FileActions.FILE_CANCEL_DELETE, payload: id };
  },
  /** @param {string} id */
  deleteFile(id) {
    return async function deletingFile(dispatch, getState) {
      await UploadsAPI.deleteUpload(id);
      dispatch({ type: FileActions.FILE_DELETE, payload: id });
    };
  },
  /** @param {object} file */
  loadFile(file) {
    return {
      type: FileActions.FILE_LOAD,
      payload: {
        ...file,
        upload_completed: true,
      },
    };
  },

  loadAllFiles() {
    return async function gettingFileListFromServer(dispatch, getState) {
      dispatch({ type: FileActions.FILES_LOAD_ALL, payload: true });
      const { files: localFiles, user } = getState();
      const serverFiles = await UploadsAPI.getUploads(user);
      // Delete local file if not found on server and not in progress...
      Object.keys(localFiles).forEach(id => {
        const localFile = localFiles[id];
        if (!(id in serverFiles) && localFile.upload_completed) {
          dispatch({ type: FileActions.FILE_DELETE, payload: id });
        }
      });
      // Add/update file...
      Object.keys(serverFiles).forEach(id => {
        const localFile = localFiles[id];
        if (localFile && !localFile.upload_completed) {
          // Don't update existing local files that are already in progress...
          return;
        }
        const file = serverFiles[id];
        if (!file.upload_completed) {
          file.show_resume = true;
        }
        dispatch({
          type: FileActions.FILE_LOAD,
          payload: file,
        });
      });
      dispatch({ type: FileActions.FILES_LOAD_ALL, payload: false });
    };
  },

  showDeleteFile(id) {
    return { type: FileActions.FILE_SHOW_DELETE, payload: id };
  },
};
