import { JoinerActions } from "./actions";
import { FileActions } from "../files/actions";
import { UploaderActions } from "../uploader/actions";

export const JoinerState = {
  name: "joiner",
  persist: false,
  defaults: {
    allowFileIds: [],
    filesToJoin: {},
    joiningToFileName: "",
    loading: false,
    sortedFileIds: [],
  },
  handlers: {
    [JoinerActions.JOIN_FILE_CHANGE]: (state, action) => {
      return {
        ...state,
        joiningToFileName: action.payload,
      };
    },
    [JoinerActions.JOIN_FILE_CREATE]: (state, action) => {
      if (action.loading) {
        return {
          ...state,
          error: undefined,
          loading: true,
        };
      }
      if (action.payload.error) {
        return {
          ...state,
          error: action.payload.error,
          loading: false,
        };
      }
      const { id, name } = action.payload;
      return {
        ...addFile(state, id, name),
        error: undefined,
        filesToJoin: {
          [id]: true, // Causes the new file to be selected.
        },
        joiningToFileName: "",
        loading: false,
      };
    },
    [JoinerActions.JOIN_FILE_POLL]: (state, action) => {
      if (action.loading || action.payload.error) {
        return state;
      }
      const { id, name } = action.payload;
      return {
        ...addFile(state, id, name),
        error: undefined,
        joiningToFileName: "",
        loading: false,
      };
    },
    [JoinerActions.JOIN_FILE_DONE]: (state, action) => {
      // const { id } = action.payload;
      return {
        ...state,
        error: undefined,
        filesToJoin: {
          // Causes all join files to be unselected.
        },
      };
    },
    [JoinerActions.JOIN_FILE_SELECT]: (state, action) => {
      const id = action.payload;
      const {
        filesToJoin: { [id]: wasSelected = false, ...otherFilesToJoin },
      } = state;
      if (wasSelected) {
        // Remove id from filesToJoin
        return {
          ...state,
          error: undefined,
          filesToJoin: otherFilesToJoin,
        };
      }
      // Add id to filesToJoin
      return {
        ...state,
        error: undefined,
        filesToJoin: {
          ...state.filesToJoin,
          [id]: true,
        },
      };
    },
    [JoinerActions.JOIN_FILE_SORT]: (state, action) => {
      const sortedFileIds = action.payload;
      return {
        ...state,
        error: undefined,
        sortedFileIds,
      };
    },
    [FileActions.FILE_ADD]: (state, action) => {
      const { upload_completed, id, name } = action.payload;
      if (!upload_completed) {
        return state;
      }
      return addFile(state, id, name);
    },
    [FileActions.FILE_DELETE]: (state, action) => {
      let changed = false;
      const id = action.payload;
      let filesToJoin = state.filesToJoin;
      if (id in state.filesToJoin) {
        changed = true;
        filesToJoin = {
          ...state.filesToJoin,
        };
        delete filesToJoin[id];
      }
      /** @type {string[]} */
      let { allowFileIds, sortedFileIds } = state;
      let foundIndex = allowFileIds.indexOf(id);
      if (foundIndex > -1) {
        changed = true;
        allowFileIds = [
          ...allowFileIds.slice(0, foundIndex),
          ...allowFileIds.slice(foundIndex + 1),
        ];
        if (sortedFileIds) {
          foundIndex = sortedFileIds.indexOf(id);
          if (foundIndex > -1) {
            sortedFileIds = [
              ...sortedFileIds.slice(0, foundIndex),
              ...sortedFileIds.slice(foundIndex + 1),
            ];
          }
        }
      }
      if (!changed) {
        return state;
      }
      return {
        ...state,
        allowFileIds,
        filesToJoin,
        sortedFileIds,
      };
    },
    [FileActions.FILE_LOAD]: (state, action) => {
      const { id, name } = action.payload;
      return addFile(state, id, name);
    },
    [FileActions.FILES_LOAD_ALL]: (state, action) => {
      return {
        ...state,
        error: undefined,
        loading: action.payload,
      };
    },
    [UploaderActions.UPLOAD_COMPLETE]: (state, action) => {
      const { id, name } = action.payload;
      return addFile(state, id, name);
    },
  },
};

function addFile(state, id, name) {
  if (!name.toLowerCase().endsWith(".mp4")) {
    return state;
  }
  const { allowFileIds, sortedFileIds } = state;
  if (allowFileIds.indexOf(id) > -1) {
    return;
  }
  if (sortedFileIds.length > 0) {
    return {
      ...state,
      allowFileIds: allowFileIds.concat(id),
      sortedFileIds: sortedFileIds.concat(id),
    };
  }
  return {
    ...state,
    allowFileIds: allowFileIds.concat(id),
  };
}
